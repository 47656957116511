import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { PT_DOCUMENT_TYPES, USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/grid/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { createKycDocumentCheckCleaned } from "../../services/kyc-document-checks";
import {
  getPersonalDataFromLs,
  removePersonalDataFromLs,
  postPersonalInfo,
  setKycStatus,
  getPreviewAgreement,
} from "../../services/users";
import DocTypeSelector from "./components/DocTypeSelector";
import CustomDragger from "../../components/CustomDragger";
import UploadedFilesList from "../../components/UploadedFileList";
import FileQualityMessage from "../../components/FileQualityMessage";
import { groupFiles, sendFile } from "../../utils/files";
import "./index.scss";
import { Context } from "../../store";
import Spin from "antd/lib/spin";
import Modal from "antd/es/modal";

function UploadKYCPage() {
  const history = useHistory();
  const [identityDoc, setIdentityDoc] = useState({});
  const [otherDoc, setOtherDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [approval, setApproval] = useState(false);
  const [previewAgreement, setPreviewAgreement] = useState("");
  const personalInfo = useMemo(() => getPersonalDataFromLs(), []);
  const [state] = useContext(Context);

  const refAgreement = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const payload = {
        ...personalInfo.personalInfo,
        ...personalInfo.residenceInfo,
        phoneNumber: personalInfo.personalInfo.phoneNumber,
      };
      const previewAgreementHTML = await getPreviewAgreement(payload);
      setPreviewAgreement(previewAgreementHTML.data.content);
    }
    fetchData();
  }, [personalInfo]);

  const groupedFiles = useMemo(() => groupFiles(files), [files]);

  if (state.user && ![USER_STATUSES_AS_OBJECT.kycFilling].includes(state.user.status)) {
    return <Redirect to="/main/dashboard" />;
  }

  // if (!personalInfo) {
  //   return <Redirect to="/personal-details" />;
  // }

  // list of documents for identity confirmation - all required
  const identityDocsOptions = PT_DOCUMENT_TYPES.filter((docType) => docType.required);

  // list of other documents should exclude all documents for identity confirmation - optional
  const otherDocsOptions = PT_DOCUMENT_TYPES.filter((docType) => !docType.required);

  const uploadDocuments = async () => {
    setLoading(true);

    try {
      await postPersonalInfo(personalInfo.personalInfo, personalInfo.residenceInfo);
      for (const key of Object.keys(groupedFiles)) {
        // key: doctype:FILE-ARRAY-LENGTH:REQUIRED(1|0) - see src/utils/files
        const [docType, filesLength] = key.split(":");

        if (+filesLength === 1) {
          const file = await sendFile(groupedFiles[key][0], docType);
          await createKycDocumentCheckCleaned({ docId: file.data.history.id });
        } else {
          const frontFile = groupedFiles[key][0];
          const backFile = groupedFiles[key][1];

          const parent = await sendFile(frontFile, docType);
          await sendFile(backFile, docType, parent.data.history.ptDocumentId);

          await createKycDocumentCheckCleaned({ docId: parent.data.history.id });
        }
      }

      await setKycStatus();

      toast(`The KYC form has been filled!`, {
        type: "success",
        position: "bottom-right",
      });

      removePersonalDataFromLs();

      history.push("/main/dashboard");
    } catch (error) {
      console.error("UploadKYCPage.upload", error);
      toast(`${error?.data?.message}`, {
        type: "error",
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }

    // try {
    //   await postPersonalInfo(personalInfo.personalInfo, personalInfo.residenceInfo);
    //
    //   const files = [...identityDocFiles, ...otherDocFiles];
    //   const url = `/api/users/personal-info/upload/${identityDocFiles[0].id}`;
    //   const data = new FormData();
    //   data.append("file", identityDocFiles[0]);
    //
    //   const defaultHeaders = getInstance().defaults.headers;
    //   const config = {
    //     headers: {
    //       ...defaultHeaders,
    //       "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
    //     },
    //   };
    //
    //   const parent = await getInstance().post(url, data, config);
    //
    //   if (identityDocFiles.length > 1) {
    //     const url = `/api/users/personal-info/upload/${identityDocFiles[1].id}/parent/${parent.data.history.ptDocumentId}`;
    //     const data = new FormData();
    //     data.append("file", identityDocFiles[1]);
    //
    //     const defaultHeaders = getInstance().defaults.headers;
    //     const config = {
    //       headers: {
    //         ...defaultHeaders,
    //         "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
    //       },
    //     };
    //
    //     await getInstance().post(url, data, config);
    //   }
    //
    //   const currentDocType = PT_DOCUMENT_TYPES.find(
    //     (docType) => docType.id === identityDocFiles[0].id
    //   );
    //   toast(`${currentDocType.label} file uploaded successfully.`, {
    //     type: "success",
    //     position: "bottom-right",
    //   });
    //
    //   await Promise.all(
    //     otherDocFiles.map(async (docFile) => {
    //       const url = `/api/users/personal-info/upload/${docFile.id}`;
    //       const data = new FormData();
    //       data.append("file", docFile);
    //
    //       const defaultHeaders = getInstance().defaults.headers;
    //       const config = {
    //         headers: {
    //           ...defaultHeaders,
    //           "content-type":
    //             "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
    //         },
    //       };
    //
    //       await getInstance().post(url, data, config);
    //
    //       const currentDocType = PT_DOCUMENT_TYPES.find((docType) => docType.id === docFile.id);
    //
    //       toast(`${currentDocType.label} file uploaded successfully.`, {
    //         type: "success",
    //         position: "bottom-right",
    //       });
    //     })
    //   );
    //
    //   await setKycStatus();
    //
    //   toast(`The KYC form has been filled!`, {
    //     type: "success",
    //     position: "bottom-right",
    //   });
    //
    //   removePersonalDataFromLs();
    //   history.push("/main/dashboard");
    // } catch (error) {
    //   console.error(error);
    //   toast(`${error?.data?.message}`, {
    //     type: "error",
    //     position: "bottom-right",
    //   });
    // } finally {
    //   setLoading(false);
    // }
  };

  const customUpload = (docType, { file, onSuccess }) => {
    file.id = docType.id;
    setFiles((prev) => [...prev, file]);
    onSuccess();
  };

  const removeFile = (file) => {
    setFiles((prev) => [...prev.filter((doc) => doc.uid !== file.uid)]);
  };

  let doc = null;

  if (previewAgreement) {
    doc = new DOMParser().parseFromString(previewAgreement, "text/html");
    const element = doc.querySelector("table.pt__signature_data tbody tr td");

    const newInput = doc.createElement("input");
    const newLabel = doc.createElement("label");
    newLabel.textContent = "authorized signature";
    newLabel.style.marginLeft = "20px";
    newInput.value = "vfecedxrf";
    const newValue = `${personalInfo.personalInfo.firstName} ${personalInfo.personalInfo.lastName}`;
    element.innerHTML = '<input disabled value="' + newValue + '" />';
    element.append(newLabel);
  }

  const isFinalButtonDisabled = (files) => {
    let requiredFilesNumber = 0;
    const groupedFiles = groupFiles(files);
    Object.keys(groupedFiles).forEach((key) => {
      const arr = key.split(":");
      const length = arr[1];
      const required = arr[2] === "1";
      if (required) {
        requiredFilesNumber += +length;
      }
    });

    const requiredFiles = files.filter((file) => file.required);

    const result =
      requiredFilesNumber === 0 || requiredFiles.length < requiredFilesNumber || !approval;

    return result;
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row className="upload-kyc-wrap" align={"flex-start"} justify={"center"}>
        <Space direction="vertical">
          <h2>Provide your documents</h2>
          <p className="upload-kyc-desc">
            This step is necessary to get access to the full range of EURST operations
          </p>
          <Divider />

          <FileQualityMessage />

          <Divider />

          <Row align={"middle"} justify={"center"} wrap>
            <Col className="upload-kyc-settings" sm={24} md={8} style={{ margin: "2rem 0" }}>
              <h3>
                Confirm Your Identity
                <br />( required )
              </h3>
              <DocTypeSelector
                onClick={setIdentityDoc}
                docOptions={identityDocsOptions}
                docType={identityDoc}
              />
            </Col>
            <Col className="upload-drag-wrap" sm={24} md={16}>
              {identityDoc.backside ? (
                <>
                  <CustomDragger
                    docType={identityDoc}
                    onRemove={removeFile}
                    customRequest={({ file, onSuccess }) => {
                      customUpload(identityDoc, { file, onSuccess });
                    }}
                    side="front"
                    files={files}
                  />
                  <Divider />
                  <CustomDragger
                    docType={identityDoc}
                    customRequest={({ file, onSuccess }) => {
                      customUpload(identityDoc, { file, onSuccess });
                    }}
                    onRemove={removeFile}
                    files={files}
                    side="back"
                  />
                </>
              ) : (
                <CustomDragger
                  docType={identityDoc}
                  onRemove={removeFile}
                  customRequest={({ file, onSuccess }) => {
                    customUpload(identityDoc, { file, onSuccess });
                  }}
                  files={files}
                  side="single"
                />
              )}
            </Col>
          </Row>
          <Divider />
          <Row align={"middle"} justify={"center"} wrap>
            <Col className="upload-kyc-settings" sm={24} md={8} style={{ margin: "2rem 0" }}>
              <h3>
                Other Documents <br />
                (optional)
              </h3>
              <DocTypeSelector
                onClick={setOtherDoc}
                docOptions={otherDocsOptions}
                docType={otherDoc}
              />
            </Col>
            <Col className="upload-drag-wrap" sm={24} md={16}>
              {otherDoc.backside ? (
                <>
                  <CustomDragger
                    docType={otherDoc}
                    onRemove={removeFile}
                    customRequest={({ file, onSuccess }) => {
                      customUpload(otherDoc, { file, onSuccess });
                    }}
                    side="front"
                    files={files}
                  />
                  <Divider />
                  <CustomDragger
                    docType={otherDoc}
                    onRemove={removeFile}
                    customRequest={({ file, onSuccess }) => {
                      customUpload(otherDoc, { file, onSuccess });
                    }}
                    files={files}
                    side="back"
                  />
                </>
              ) : (
                <CustomDragger
                  docType={otherDoc}
                  onRemove={removeFile}
                  customRequest={({ file, onSuccess }) => {
                    customUpload(otherDoc, { file, onSuccess });
                  }}
                  files={files}
                  side="single"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={24} style={{ margin: "2rem 0" }}>
              <h3>Files to upload</h3>
              <UploadedFilesList onDelete={removeFile} groupedFiles={groupedFiles} />
            </Col>
          </Row>
          <Row align={"middle"} justify={"center"} wrap>
            <Col sm={24} style={{ margin: "2rem 0" }}>
              <label className="finish-kyc-checkbox">
                <Checkbox checked={approval} disabled={!approval} /> I give my personal consent to
                check the data provided in this KYC form.
              </label>
              <a
                href={"#"}
                style={{ display: "block", textAlign: "left" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVisible(true);
                }}>
                See Preview Agreement
              </a>
              <p className="kyc-finish-note">
                Note:
                <br />
                The KYC procedure check requires 1-5 days.
                <br />
                You will receive an email when it is complete.
                <br />
                After successful completion you will have access to the services.
                <br />
              </p>
            </Col>
          </Row>

          <Row>
            <Button
              className="primary-button"
              disabled={isFinalButtonDisabled(files)}
              loading={loading}
              onClick={uploadDocuments}
              type="primary">
              Upload documents and finish
            </Button>
          </Row>
        </Space>
        <Modal
          title="Self-Directed Custodial Account Agreement"
          centered
          visible={visible}
          onOk={() => {
            setVisible(false);
            setApproval(true);
          }}
          okText={"Agree"}
          onCancel={() => {
            setVisible(false);
            setApproval(false);
          }}
          width={1000}>
          <div
            ref={refAgreement}
            dangerouslySetInnerHTML={{
              __html: doc ? new XMLSerializer().serializeToString(doc) : "",
            }}></div>
        </Modal>
      </Row>
    </Spin>
  );
}

export default UploadKYCPage;
