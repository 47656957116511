import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";

import auth from "../../../services/auth";

const ResetPasswordConfirmPage = () => {
  const [loading, setLoading] = useState(false);
  const [tempToken, setTempToken] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    setTempToken(query.get("token"));
    history.push({ search: "" });
  }, [history]);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const { data } = await auth.resetPasswordConfirm(tempToken, values);

      toast(data.message, {
        type: "success",
      });
    } finally {
      setLoading(false);
      history.push("/login");
    }
  };

  return (
    <div className="login-wrap">
      <h1 className="registration-header">Update your password</h1>
      <Card className="login-card" type={"inner"}>
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ password: "", confirm: "" }}
          onFieldsChange={(_, fields) => {
            const errors = fields.reduce((acc, field) => [...acc, ...field.errors], []);
            console.log(!errors.length);
            setIsValid(!errors.length);
          }}
          onFinish={onFinish}>
          <Form.Item
            label="Password"
            name="password"
            style={{
              textAlign: "left",
            }}
            hasFeedback
            rules={[
              {
                required: true,
                max: 255,
              },
              {
                min: 8,
                message: "Password must be more than 8 symbols!",
              },
              {
                pattern: /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]/,
                message: "Password is not valid!",
              },
            ]}>
            <Input.Password
              prefix={
                <Tooltip
                  title={
                    <div>
                      <span>Password must:</span>
                      <ol>
                        <li>be eight or more characters long.</li>
                        <li>
                          contain:
                          <ul style={{ paddingLeft: "10px" }}>
                            <li>Uppercase characters A-Z (Latin alphabet);</li>
                            <li>Lowercase characters a-z (Latin alphabet);</li>
                            <li>Digits 0-9;</li>
                            <li>Special characters (!, $, #, %, etc.).</li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  }>
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            style={{
              textAlign: "left",
            }}
            rules={[
              { required: true, max: 255 },
              {
                min: 8,
                message: "Confirm password must be more than 8 symbols!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match!");
                },
              }),
            ]}>
            <Input.Password placeholder="Confirm password" />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={!isValid}
              className="primary-button"
              type="primary"
              htmlType="submit"
              loading={loading}>
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

ResetPasswordConfirmPage.propTypes = {};

export default ResetPasswordConfirmPage;
