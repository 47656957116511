import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "antd/lib/layout";
import DashboardPage from "../DashboardPage";
import TransactionsPage from "../TransactionsPage";
import Header from "../../components/Header";
import MintProcessPage from "../MintProcessPage";
import WirePaymentPage from "../WirePaymentPage";
import WithdrawPage from "../WithdrawPage";
import RedeemProcessPage from "../RedeemProcessPage";
import ProfilePage from "../ProfilePage";
import BankDetailsPage from "../BankDetailsPage";
import CardPayment from "../CardPayment";
import Anchor from "antd/lib/anchor";

function MainTemplate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Anchor targetOffset={1000}>
      <Layout>
        <Header status={status} />
        <Switch>
          <Route path="/main/dashboard" component={DashboardPage} />
          <Route path="/main/transactions" component={TransactionsPage} />
          <Route path="/main/mint-process" component={MintProcessPage} />
          <Route path="/main/wire-payment" component={WirePaymentPage} />
          <Route path="/main/redeem-process" component={RedeemProcessPage} />
          <Route path="/main/withdraw" component={WithdrawPage} />
          <Route path="/main/profile" component={ProfilePage} />
          <Route path="/main/bank-details" component={BankDetailsPage} />
          <Route path="/main/card-payment" component={CardPayment} />
          <Route path="*" component={() => <Redirect to="/main/dashboard" />} />
        </Switch>
      </Layout>
    </Anchor>
  );
}

export default MainTemplate;
