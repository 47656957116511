/* eslint-disable */
import React, { useContext, useState } from "react";
import { Button, Form, Input, Row } from "antd";
import Card from "antd/lib/card";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import "./index.scss";
import auth from "../../services/auth";
import Cookies from "js-cookie";
import { Link, Redirect, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import { getOwnerSettings } from "../../services/general";
import { Context } from "../../store";

function LoginPage() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const onFinish = async (values) => {
    ReactGA.event({
      category: "Sign Up",
      action: "User pressed the big blue log in button",
    });
    setLoading(true);
    try {
      const token = await auth.login(values);
      if (token) {
        const response = await getOwnerSettings();

        dispatch({ type: "SET_OWNER_SETTINGS", payload: response.data });
        history.push("/main/dashboard");
      }
    } catch (error) {
      console.error("login page", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancelClick = () => {
    history.goBack();
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  // const token = auth.getAuthCookies();
  // if (token) {
  //   // history.push("/main/dashboard");
  //   return <Redirect to="/main/dashboard" />
  // }
  React.useEffect(() => {
    const token = auth.getAuthCookies();
    if (token) {
      history.push("/main/dashboard");
    }
  }, []);
  return (
    <div className="login-wrap">
      <h1>Login to start!</h1>
      <Card className="login-card">
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFieldsChange={handlerFieldsChange}
          validateMessages={validateMessages}
          onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                whitespace: true,
              },
              { type: "email" },
            ]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              onChange={() => console.log("hi")}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                type: "string",
                min: 8,
                message: "Password must be more than 8 symbols",
              },
            ]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Row justify={"space-between"}>
              <Button className="secondary-button" onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
                loading={loading}
                className="primary-button">
                Log in
              </Button>
            </Row>
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "left",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              <Link className="login-form-forgot" to="/reset-password">
                Forgot password
              </Link>
              <Link
                to="/check-country"
                style={{
                  marginTop: "10px",
                }}>
                Create a new account
              </Link>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default LoginPage;
