/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import Button from "antd/lib/button";
import DatePicker from "antd/lib/date-picker";
import Input from "antd/lib/input";
import Layout from "antd/lib/layout";
import Link from "antd/lib/typography/Link";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Tabs from "antd/lib/tabs";
import Tag from "antd/lib/tag";
import {
  FORMAT_FOR_DATE,
  FORMAT_FOR_DATE_WITH_TIME,
} from "eurst-admin-part/src/constants/constants";
import {
  BALANCE_STATUSES,
  BALANCE_TYPES_AS_OBJECT,
  PURCHASE_SOURCES,
  PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY,
  WITHDRAW_STATUSES,
  WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY,
} from "eurst-shared/src/enums";
import { bgnPlus } from "eurst-shared/src/helpers/math";
import { shortener } from "eurst-shared/src/helpers/string";
import { transactionExplorerUrl } from "eurst-shared/src/helpers/urls";
import moment from "moment";
import Highlighter from "react-highlight-words";

import { STATUSES_COLOR, STATUSES_COLOR_FOR_WITHDRAW } from "../../constants/constants";
import { useFragmentState } from "../../hooks";
import { getPurchasesList, getWithdrawList } from "../../services/wallet";
import { Context } from "../../store";
import "./index.scss";
import withRedirect from "../../hocs/withKYCApproved";
import { Tooltip } from "antd";
import InfoCircleOutlined from "@ant-design/icons/lib/icons/InfoCircleOutlined";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function TransactionsPage() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    defaultCurrent: 1,
    onChange: (page) => {
      setCurrentPage(page);
    },
    showSizeChanger: false,
  });
  const [tableContentType, setTableContentType] = useFragmentState("mint");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchRange, setSearchRange] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [state, dispatch] = useContext(Context);

  let searchInput = null;

  useEffect(() => {
    dispatch({ type: "SET_CURRENT_NAV", payload: "transactions" });
    fetchTableData(tableContentType).catch(console.error);
  }, []);

  const onChangeRangePicker = (dates, dateStrings, setSelectedKeys) => {
    setSelectedKeys([[dateStrings[0], dateStrings[1]].join("!")]);
  };

  const onChangeAmountFilter = (e, setSelectedKeys) => {
    setMinAmount(e.target.value ? [e.target.value] : []);
    setSelectedKeys([e.target.value]);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getColumnSearchByRangeProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`From`}
          value={selectedKeys[0]}
          onChange={(e) => onChangeAmountFilter(e, setSelectedKeys)}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Input
          placeholder={`To`}
          value={selectedKeys[1]}
          onChange={(e) => setMaxAmount(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return (
        parseFloat(minAmount) <= parseFloat(record[dataIndex]) &&
        parseFloat(record[dataIndex]) <= parseFloat(maxAmount)
      );
    },
  });

  const getColumnSearchByIntervalProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
        <RangePicker
          inputReadOnly
          showTime
          format={FORMAT_FOR_DATE}
          onChange={(dates, dateStrings) =>
            onChangeRangePicker(dates, dateStrings, setSelectedKeys)
          }
          style={{ width: 250, marginBottom: 8 }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchByRange(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button
            onClick={() => handleResetByRange(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const range = value.split("!");
      return (
        moment(range[0], FORMAT_FOR_DATE).valueOf() <
          moment(record.date, FORMAT_FOR_DATE).valueOf() &&
        moment(record.date, FORMAT_FOR_DATE).valueOf() < moment(range[1], FORMAT_FOR_DATE).valueOf()
      );
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={searchRange}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearchByRange = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchRange(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  const handleResetByRange = (clearFilters) => {
    clearFilters();
    setSearchRange([]);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const fetchTableData = async (type) => {
    if (type === BALANCE_TYPES_AS_OBJECT.withdraw) {
      setLoading(true);
      const data = await getWithdrawList();
      setWithdraws(
        data.map((item) => ({
          ...item,
          date: moment(item.createdAt).format(FORMAT_FOR_DATE_WITH_TIME),
        }))
      );
      setLoading(false);
    } else {
      setLoading(true);
      const data = await getPurchasesList(type);
      setTransactions(
        data.map((item) => ({
          ...item,
          date: moment(item.createdAt).format(FORMAT_FOR_DATE_WITH_TIME),
        }))
      );
      setLoading(false);
    }
  };

  const handleTableChange = async () => {
    await fetchTableData(tableContentType);
  };

  const handleTabsChange = async (key) => {
    await fetchTableData(key);
    setTableContentType(key);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "id-col",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchByIntervalProps("date"),
      className: "date-col",
    },
    {
      title: "EURST amount",
      dataIndex: "eurAmount",
      key: "eurst",
      ...getColumnSearchByRangeProps("eurAmount"),
      className: "eurst-col",
    },
    {
      title: "USD payment amount",
      dataIndex: "amount",
      key: "usd",
      ...getColumnSearchByRangeProps("amount"),
      render: (value, row) => bgnPlus(value, row.fee),
      className: "usd-col",
    },
    {
      title: "Payment method",
      dataIndex: "source",
      key: "source",
      filters: PURCHASE_SOURCES.map(({ id, label }) => ({
        text: label,
        value: id,
      })),
      onFilter: (value, record) => record.source.indexOf(value) === 0,
      render: (source) => {
        return (
          <React.Fragment>
            <span>{PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY[source].label}</span>
          </React.Fragment>
        );
      },
      className: "source-col",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: Object.values(BALANCE_STATUSES).map(({ id, label }) => ({
        text: label,
        value: id,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status, record) => {
        let color = STATUSES_COLOR[status];
        return (
          <React.Fragment>
            <Tooltip title={record.message}>
              <Tag color={color} key={status}>
                {BALANCE_STATUSES[status].label}
              </Tag>
              {record.message && <InfoCircleOutlined />}
            </Tooltip>
          </React.Fragment>
        );
      },
      className: "status-col",
    },
    {
      title: "Reference",
      dataIndex: "trnHash",
      key: "trnHash",
      ...getColumnSearchProps("trnHash"),
      render: (trnHash) => {
        if (!trnHash) return "-";

        const url = transactionExplorerUrl(process.env.REACT_APP_NETWORK, trnHash);

        return (
          <Link href={url} target="_blank" copyable={{ text: trnHash }}>
            {shortener(trnHash)}
          </Link>
        );
      },
      className: "reference-col",
    },
    {
      title: "Fee (USD)",
      dataIndex: "fee",
      key: "fee",
      render: (value) => Math.abs(parseFloat(value)),
      className: "fee-col",
    },
  ];
  const columnsForWithdrawTable = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "id-col",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchByIntervalProps("date"),
      className: "date-col",
    },
    {
      title: "USD payment amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchByRangeProps("amount"),
      className: "amount-col",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: WITHDRAW_STATUSES.map(({ id, label }) => ({
        text: label,
        value: id,
      })),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status, record) => {
        console.log("record", record);
        let color = STATUSES_COLOR_FOR_WITHDRAW[status];
        return (
          <React.Fragment>
            <Tag color={color} key={status}>
              {WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY[status].label}
            </Tag>
          </React.Fragment>
        );
      },
      className: "status-col",
    },
  ];

  return (
    <Content>
      <Tabs
        className="transactions-tabs-wrap"
        onChange={handleTabsChange}
        type="card"
        activeKey={tableContentType}>
        <TabPane tab="Minting" key={BALANCE_TYPES_AS_OBJECT.mint}>
          <Table
            key={BALANCE_TYPES_AS_OBJECT.mint}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              current: currentPage,
            }}
            dataSource={transactions}
            loading={loading}
            columns={columns}
          />
        </TabPane>
        <TabPane tab="Redeeming" key={BALANCE_TYPES_AS_OBJECT.redeem}>
          <Table
            key={BALANCE_TYPES_AS_OBJECT.redeem}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              current: currentPage,
            }}
            dataSource={transactions}
            loading={loading}
            columns={columns}
          />
        </TabPane>
        <TabPane tab="Withdraw" key={BALANCE_TYPES_AS_OBJECT.withdraw}>
          <Table
            key={BALANCE_TYPES_AS_OBJECT.withdraw}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              current: currentPage,
            }}
            dataSource={withdraws}
            loading={loading}
            columns={columnsForWithdrawTable}
          />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default withRedirect(TransactionsPage);
