module.exports = {
  transactionExplorerUrl: (network, transactionHash) => {
    if (network === 'mainnet') {
      return `https://etherscan.io/tx/${transactionHash}`
    }
    // https://rinkeby.etherscan.io/tx/0x8bc2ae69a23fd9bdc44ceb630d4e6f941d52884b72aab076c27fffa0c6855de5
    return `https://${network}.etherscan.io/tx/${transactionHash}`
  },
  /* examples:
   *
   * etherscanUrl('rinkeby', 'tx', 'TRANSACTION_HASH) - url for transaction
   * etherscanUrl('rinkeby', 'address', 'ETH_ADDRESS) - url for address
   * */
  etherscanUrl: (network, type, string) => {
    if (!string) return ''

    if (network === 'mainnet') {
      return `https://etherscan.io/${type}/${string}`
    }
    return `https://${network}.etherscan.io/${type}/${string}`
  },
}
