/* eslint-disable */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Avatar, Menu } from "antd";
import Dropdown from "antd/lib/dropdown";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { STATUS_MESSAGES, STATUSES_KYC_COLOR } from "../../constants/constants";
import { USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Context } from "../../store";
import "./index.scss";
import WallexLogo from "../../Eurst-Black@1.5x-2048x664.png";

const menu = () => {
  return (
    <Menu>
      <Menu.Item key="profile">
        <a href="/main/profile">Profile</a>
      </Menu.Item>

      <Menu.Item key="logout">
        <a href="/logout">Logout</a>
      </Menu.Item>
    </Menu>
  );
};

function Header(props) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { user } = state;
  const statusIndicator = STATUS_MESSAGES[user.status];

  const handleClick = (e) => {
    dispatch({ type: "SET_CURRENT_NAV", payload: e.key });
  };

  let currentNav = null;
  const {
    location: { pathname },
  } = props;
  if (pathname.includes("dashboard")) {
    currentNav = "dashboard";
  } else if (pathname.includes("transactions")) {
    currentNav = "transactions";
  } else {
    currentNav = "";
  }

  return (
    <nav>
      <div className="navigation-wrap">
        <div className="logo">
          <img src={WallexLogo} alt="logo" />
        </div>

        <Menu
          className="desctop-menu"
          onClick={handleClick}
          selectedKeys={[currentNav]}
          mode="horizontal">
          <Menu.Item key="dashboard">
            <Link to="/main/dashboard">Dashboard</Link>
          </Menu.Item>
          {user && user.status === USER_STATUSES_AS_OBJECT.kycApproved && (
            <Menu.Item key="transactions">
              <Link to="/main/transactions">Transactions</Link>
            </Menu.Item>
          )}
        </Menu>

        <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
          <div className={"user-menu"}>
            <Avatar
              style={{
                cursor: "pointer",
                border: `4px solid ${STATUSES_KYC_COLOR[user.status]}`,
              }}
              size={"large"}
              alt={user.userName}
              icon={<UserOutlined />}
            />
            <DownOutlined />
          </div>
        </Dropdown>
      </div>

      <Menu
        className="mobile-menu"
        onClick={handleClick}
        selectedKeys={[currentNav]}
        mode="horizontal">
        <Menu.Item key="dashboard">
          <Link to="/main/dashboard">Dashboard</Link>
        </Menu.Item>
        {user && user.status === USER_STATUSES_AS_OBJECT.kycApproved && (
          <Menu.Item key="transactions" className="menu-item-transactions">
            <Link to="/main/transactions">Transactions</Link>
          </Menu.Item>
        )}
      </Menu>
    </nav>
  );
}

Header.propTypes = {
  status: PropTypes.string,
};

export default withRouter(Header);
