import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { ethers } from "ethers";
import {
  calculationOfDecimalNumbers,
  fromIntOtEthBN,
  calculateRedeemPaymentData,
  revertRedeemPaymentDataToEURST,
} from "eurst-shared/src/helpers/math";
import { shortener } from "eurst-shared/src/helpers/string";
import { transactionExplorerUrl } from "eurst-shared/src/helpers/urls";

import DollarCircleOutlined from "@ant-design/icons/lib/icons/DollarCircleOutlined";
import Button from "antd/lib/button";
import Col from "antd/lib/grid/col";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Layout from "antd/lib/layout";
import Link from "antd/lib/typography/Link";
import Modal from "antd/lib/modal";
import Row from "antd/lib/grid/row";
import Spin from "antd/lib/spin";
import useForm from "antd/lib/form/hooks/useForm";
import { withMetaMask } from "../../hocs";
import WallexLogo from "../../eurst-symbol.png";
import { getUser } from "../../services/users";
import { getDeposit, getRate, getSCProfile, getEurstInfo } from "../../services/wallet";
import { Context } from "../../store";
import { Breadcrumb } from "antd";
import withKYCApproved from "../../hocs/withKYCApproved";

// TODO remove this commented later
//const { address: CONTRACT_ADDRESS, abi: CONTRACT_ABI } = EURST;

const { Content } = Layout;

function RedeemProcessPage({ metaMask }) {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { redeemFee, redeemFeeAsOne, minRedeemAmount, maxRedeemAmount } = state.ownerSettings;
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [transactionLoad, setTransactionLoad] = useState(false);
  const [wallet, setWallet] = useState("");
  const [balance, setBalance] = useState(0);
  const [deposit, setDeposit] = useState(0);

  const [rate, setRate] = useState(0);
  const [usd, setUsd] = useState(null);
  const [eurst, setEurst] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await fetchUserData();
        await fetchRate();
        await fetchDeposit();
      } catch (error) {
        console.error("redeem page", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fetchUserData = async () => {
    const { data } = await getUser();
    setBalance(data.balance ? data.balance : null);
    setWallet(data.ethAddress ? data.ethAddress : null);
  };

  const fetchRate = async () => {
    const { data } = await getRate("USD");
    setRate(data.USD ? data.USD : null);
  };

  const fetchDeposit = async () => {
    const {
      data: {
        deposit: { disbursable },
      },
    } = await getDeposit();
    setDeposit(disbursable);
  };

  const onClickButton = async () => {
    dispatch({ type: "SET_AMOUNT_USD", payload: usd });
    dispatch({ type: "SET_AMOUNT_EURST", payload: eurst });
    setProcessing(true);
    try {
      if (metaMask.isConnected()) {
        await metaMask.checkCurrentUser();
        await metaMask.checkCurrentNetwork();
        await connectToMetaMask(eurst);
        history.push("/main/dashboard");
      } else {
        metaMask.presentWarning.notConnected();
      }
    } catch (e) {
      if (e.code) {
        metaMask.presentWarning.code(e.code, e?.message);
      } else if (e.title) {
        info(e.title, e?.content);
      } else if (e.message) {
        info(e.message);
      } else {
        console.error("redeem page", e);
      }
    } finally {
      setProcessing(false);
    }
  };

  const connectToMetaMask = async (amount) => {
    const { data } = await getSCProfile();
    // get independent from eth network contract info
    const eurstAbi = await getEurstInfo();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(wallet);
    // TODO remove this commented later
    //const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
    const contract = new ethers.Contract(eurstAbi.data.address, eurstAbi.data.abi, signer);

    contract.connect(signer);
    contract.attach(wallet);

    const { hash: txHash, wait: waitForCurrentTransaction } = await contract.transfer(
      data.redeemAddress,
      fromIntOtEthBN(amount)
    );
    setUsd(null);
    setEurst(null);

    setTransactionLoad(true);
    await waitForCurrentTransaction().finally(() => setTransactionLoad(false));

    const url = transactionExplorerUrl(process.env.REACT_APP_NETWORK, txHash);
    const content = (
      <>
        <span>Your transaction is accepted. More details about it you can find here: </span>
        <Link href={url} target="_blank" copyable={{ text: txHash }}>
          {shortener(txHash, 12, 10)}
        </Link>
      </>
    );
    info("Congratulations!", content);

    await fetchDeposit();
  };

  const calculatedUSD = (value) => {
    const paymentAmounts = calculateRedeemPaymentData(value, rate, redeemFeeAsOne);
    paymentAmounts.totalAmount = parseFloat(paymentAmounts.totalAmount.toFixed(2));

    return paymentAmounts;
  };

  const calculatedEURST = (value) => {
    const paymentAmounts = revertRedeemPaymentDataToEURST(value, rate, redeemFeeAsOne);
    paymentAmounts.totalAmount = parseFloat(paymentAmounts.totalAmount.toFixed(2));

    return paymentAmounts;
  };

  const info = (title, content) => {
    Modal.info({
      title: title,
      content: (
        <div>
          <p>{content}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const validateField = (_, value) => {
    const valueToCheck = +value === 0 ? 0 : +value || value;

    return new Promise((resolve, reject) => {
      if (!valueToCheck) {
        reject("This field cannot be empty");
      } else if (typeof valueToCheck !== "number") {
        reject("The value can be only a number");
      } else if (valueToCheck < minRedeemAmount) {
        reject(`The value should be greater than ${minRedeemAmount}`);
      } else if (valueToCheck > maxRedeemAmount) {
        reject(`The value should be less than ${maxRedeemAmount}`);
      } else if (parseFloat(eurst + "") > parseFloat(balance + "")) {
        reject("Insufficient balance!");
      } else {
        resolve();
      }
    });
  };

  const onFormValueChange = async (currentField) => {
    const { usd: usdValue, eurst: eurstValue } = currentField;

    try {
      if (usdValue) {
        setUsd(usdValue);
        await validateField({}, usdValue);

        const { amount, totalAmount } = calculatedEURST(usdValue);

        if (amount === totalAmount) {
          setEurst(0);

          form.setFieldsValue({
            eurst: 0,
          });
        } else if (parseFloat(totalAmount + "") <= parseFloat(balance + "") && totalAmount >= 0) {
          setEurst(totalAmount);

          form.setFieldsValue({
            eurst: totalAmount,
          });
        } else {
          await Promise.reject("Amount is not valid");
        }
      } else if (eurstValue) {
        setEurst(eurstValue);
        await validateField({}, eurstValue);

        const { amount, totalAmount } = calculatedUSD(eurstValue);
        if (amount === totalAmount) {
          setUsd(0);

          form.setFieldsValue({
            usd: 0,
          });
        } else if (totalAmount >= 0) {
          setUsd(totalAmount);

          form.setFieldsValue({
            usd: totalAmount,
          });
        } else {
          await Promise.reject("Amount is not valid");
        }

        form.setFieldsValue({
          usd: totalAmount,
        });
      }
    } catch {
      if (usdValue) {
        form.setFieldsValue({
          eurst: null,
        });
      } else if (eurstValue) {
        form.setFieldsValue({
          usd: null,
        });
      }
    }
  };

  const normalizeValue = (value, defaultValue) => {
    const numbersAfterDot = calculationOfDecimalNumbers(value);
    return numbersAfterDot <= 2 ? value : defaultValue;
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="dashboard">
      <RouterLink to="/main/dashboard">Dashboard</RouterLink>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="redeem">
      <RouterLink to="/main/redeem-process">Redeem</RouterLink>
    </Breadcrumb.Item>,
  ];

  return (
    <React.Fragment>
      <Spin size="large" tip="Loading..." spinning={loading}>
        <Layout className="mint-wrap">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          <Content>
            <Row>
              <Col className="mint-col" span={12} offset={2}>
                <Spin
                  size="large"
                  tip="The transaction is in progress..."
                  spinning={transactionLoad}>
                  <Divider orientation="left">Redeem EURST</Divider>
                  <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ eurst: null, usd: null }}
                    onFieldsChange={(_, fields) => {
                      const errors = fields.reduce((acc, field) => [...acc, ...field.errors], []);
                      setIsFilled(fields.every((field) => field.value));
                      setIsValid(!errors.length);
                    }}
                    onValuesChange={onFormValueChange}
                    size={"default"}>
                    <Form.Item label="Destination Address">
                      <Input value={wallet} disabled />
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={false}
                      label="Amount"
                      name="eurst"
                      normalize={(value) => normalizeValue(value, eurst)}
                      rules={[{ validator: validateField }]}>
                      <Input suffix="EURST" placeholder="0" />
                    </Form.Item>
                    <Form.Item
                      shouldUpdate
                      label="USD"
                      name="usd"
                      normalize={(value) => normalizeValue(value, usd)}
                      rules={[{ validator: validateField }]}>
                      <Input prefix="$" placeholder="0" suffix="USD" />
                    </Form.Item>
                    <p style={{ textAlign: "left" }}>
                      * The amount to pay is reduced by {redeemFee}% commission fee.
                    </p>
                    <Form.Item label="Rate">
                      <span>1 EURST = {rate} USD</span>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="primary-button"
                        loading={processing}
                        disabled={!isValid || !isFilled || !balance}
                        onClick={onClickButton}
                        type="primary">
                        Redeem to deposit your USD at Wallex
                      </Button>
                    </Form.Item>
                  </Form>
                </Spin>
              </Col>
              <Col className="mint-col" span={12} offset={2}>
                <Divider orientation="left">EURST balance</Divider>
                <Row justify={"space-between"}>
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <img src={WallexLogo} alt="logo" />
                    </div>
                    <Input disabled value={balance} placeholder="0" />
                  </div>
                </Row>
                <Divider orientation="left">USD deposit at Wallex</Divider>
                <Row justify={"space-around"}>
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <DollarCircleOutlined style={{ fontSize: "60px" }} />
                    </div>
                    <Input disabled value={deposit} placeholder="0" />
                  </div>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </React.Fragment>
  );
}

export default withMetaMask(withKYCApproved(RedeemProcessPage));
