import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

function getOwnerSettings() {
  const url = `/api/owner-settings`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function getResourceToken() {
  const url = `/api/accounts/resource-token?type=company`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function postAccounts(accountId) {
  const url = `/api/accounts`;
  const payload = {
    accountId,
  };
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.post(url, payload);
}

export { getOwnerSettings, getResourceToken, postAccounts };
