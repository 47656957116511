import React, { useState } from "react";
import auth from "../../services/auth";
import Row from "antd/lib/row";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Typography from "antd/lib/typography";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

//import "./index.css";

const { Title } = Typography;

const validateMessages = {
  required: "${label} is required",
  types: {
    email: "${label} is not a valid email!",
  },
};

const tailLayout = {
  //wrapperCol: { offset: 21 },
};

const ResendRegcodePage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const result = await auth.resendRegLink({ email: values.email });

      setLoading(false);

      toast(result.data.message, {
        type: "success",
        position: "bottom-right",
      });
      history.push("/");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row align={"middle"} justify={"center"} style={{ height: "80vh" }}>
        <Card
          title={<Title level={3}>Re-send account verification link</Title>}
          className="reset-password-card"
          type={"inner"}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ email: "" }}
            onFinish={onFinish}
            onFieldsChange={handlerFieldsChange}
            validateMessages={validateMessages}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                {
                  required: true,
                  message: "Please enter a valid email address!",
                },
              ]}
              extra={<div>Confirmation link will be sent to email</div>}>
              <Input placeholder="Your account email" />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" disabled={disabled} htmlType="submit" loading={loading}>
                Re-send
              </Button>
            </Form.Item>
            <Form.Item
              style={{
                textAlign: "left",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Link className="login-form-forgot" to="/reset-password">
                  Forgot password
                </Link>
                <Link
                  to="/check-country"
                  style={{
                    marginTop: "10px",
                  }}>
                  Create a new account
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    </div>
  );
};

export default ResendRegcodePage;
