import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

function updateKycDocumentStatus(id) {
  const url = `/api/kyc-document-checks/${id}/status`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url);
}

function createKycDocumentChecksInPt(id, payload) {
  const url = `/api/kyc-document-checks/${id}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

function createKycDocumentCheckCleaned(payload) {
  const url = `/api/kyc-document-checks/check-only/create`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

export { updateKycDocumentStatus, createKycDocumentChecksInPt, createKycDocumentCheckCleaned };
