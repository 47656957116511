import { useCallback, useEffect, useRef, useState } from "react";

/**
 * This one hook only updates state if the component that called this hook is mounted. This allows us to avoid memory leaks.
 * @param {*} initialState
 * @param {boolean} [lifeInitFlag]
 * @returns {[*, function(*): void]}
 */
export default function useSuperState(initialState, lifeInitFlag = false) {
  const [state, _setState] = useState(initialState);
  const flag = useRef(lifeInitFlag);

  useEffect(() => {
    return () => {
      flag.current = false;
    };
  }, []);

  const setState = useCallback((newState) => flag.current && _setState(newState), [_setState]);

  return [state, setState];
}
