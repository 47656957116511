import React from "react";
import { toast } from "react-toastify";
import Upload from "antd/lib/upload/Upload";
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import { getFileList, previewFile } from "../../utils/files";

const UploadButton = ({ label }) => {
  return (
    <div>
      <UploadOutlined />
      <div style={{ marginTop: 8 }}>{label}</div>
    </div>
  );
};

const CustomDragger = ({ docType, side, files, onRemove, customRequest, disabled }) => {
  const componentDisabled = !docType?.label || disabled;

  const fileList = getFileList(files, side, docType.id);

  let label = "Upload a document";
  if (docType.label) {
    switch (side) {
      case "front":
        label = `Upload front side of ${docType.label}`;
        break;
      case "back":
        label = `Upload back side of ${docType.label}`;
        break;
      default:
        label = `Upload ${/o|u/i.test(docType.label) ? "an" : "a"} ${docType.label}`;
        break;
    }
  }

  const beforeUpload = (file) => {
    try {
      const isFileUnique = fileList.every(
        (doc) => doc.size !== file.size && doc.name !== file.name
      );
      if (!isFileUnique) throw "You have already selected this file!\nDon't duplicate, please.";

      const hasFileFitSize = file.size < 5000000;
      if (!hasFileFitSize) throw "Your document is too large!\nTry another file, please.";

      const hasFileAllowedExtension = /image/.test(file.type) || /pdf/.test(file.type);
      if (!hasFileAllowedExtension)
        throw "You can upload only pictures and PDF!\nTry another file, please.";

      const maxFilesLimit = 8;
      const isAmountLimit = files?.length < maxFilesLimit;
      if (!isAmountLimit) throw `You can upload only ${maxFilesLimit} documents!`;

      file.docType = docType.id;
      file.required = docType.required;
      file.backside = docType.backside;
      file.side = side;
    } catch (message) {
      toast(message, {
        type: "warning",
        position: "bottom-right",
      });
      return false;
    }
  };

  const uploadProps = {
    accept: "image/*, .pdf",
    multiple: false,
    listType: "picture",
    showUploadList: true,
    disabled: componentDisabled,
    fileList,
    onRemove,
    customRequest,
    onPreview: previewFile,
    beforeUpload: beforeUpload,
  };

  return (
    <>
      <Upload {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <UploadButton label={label} />
        </p>
      </Upload>
    </>
  );
};

CustomDragger.propTypes = {
  uniqueProps: PropTypes.object,
  docType: PropTypes.object,
  side: PropTypes.string,
  onRemove: PropTypes.func,
  customRequest: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CustomDragger;
