import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import Reducer from "../reducer";

const initialState = {
  personalInfo: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    taxIdNumber: "",
    citizenship: "",
    taxState: null,
  },
  residenceInfo: {
    address1: "string",
    address2: "string",
    city: "string",
    zip: "string",
    phoneNumber: "+12025550180",
  },
  user: {},
  ownerSettings: {},
  currentNav: "dashboard",
  countryCode: "",
  withdrawAmount: 0,
  amountUSD: 0,
  amountEURST: 0,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
Store.propTypes = {
  children: PropTypes.any,
};
export default Store;
