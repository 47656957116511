/* eslint-disable */
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import { Context } from "../store";

const withKYCApproved = (Component) => (props) => {
  const [state, dispatch] = useContext(Context);
  const {
    user: { status },
  } = state;

  if (status !== USER_STATUSES_AS_OBJECT.kycApproved) {
    return <Redirect to="/main/dashboard" />;
  }
  return <Component {...props} />;
};

export default withKYCApproved;
