import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import auth from "../../services/auth";
import { useSetInterval } from "../../hooks";
// import { getUser } from "../../services/users";
// import { Context } from "../../store";
// import Spin from "antd/lib/spin";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const token = auth.getAuthCookies();
  const checkJwtTokenExpiresAt = () => {
    const authData = auth.getAuthCookies();

    if (authData) {
      console.info("checkJwtTokenExpiresAt - skip");
      return;
    }
    auth.logout();
    history.push("/");
  };

  useSetInterval(checkJwtTokenExpiresAt, 5000, {});

  // const [loading, setLoading] = useState(false);

  /* eslint-disable */
  // const [state, dispatch] = useContext(Context);
  /* eslint-enable */

  // useEffect(() => {
  //   if (rest.path !== "/logout" && token) {
  //     (async () => {
  //       try {
  //         const response = await getUser();
  //         dispatch({ type: "SET_USER", payload: response.data });
  //       } catch (error) {
  //         console.error("router", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     })();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [dispatch, rest.path, token]);

  if (!token) {
    return <Redirect to="/" />;
  }

  // if (loading) return <Spin size="large" className="ant-spinner" />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <div>
          <Component {...props} />
        </div>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
