import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import auth from "../services/auth";

const apiUrl = process.env.REACT_APP_BACKEND_HOST;

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

instance.interceptors.response.use(
  function (response) {
    if (get(response, "data.include")) {
      return response.data;
    }
    if (get(response, "data.data")) {
      return response.data.data;
    }
    return response;
  },
  function (error) {
    let response = get(error, "response");
    console.info("response", error);
    if (!response) {
      response = error.toJSON();
    }
    if (response.status === 422 && response.data.code === "pt-error") {
      toast(response.data.details[0].detail, {
        type: "error",
      });
      return;
    }
    toast(response?.data?.message, {
      type: "error",
    });
    if (response.status === 401 && response.data.name === "token-expired") {
      auth.logout();

      setTimeout(() => {
        if (location.pathname !== "/") location.assign("/");
      }, 500);
    }

    return Promise.reject(response);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");

    const { origin } = new URL(`${config.baseURL}${config.url}`);
    const allowedOrigins = [apiUrl];

    if (allowedOrigins.includes(origin)) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// function setAuthToken(token) {
//   instance.defaults.headers.common['Authorization'] = '';
//   delete axios.defaults.headers.common['Authorization'];
//   console.info('token', token)
//   if (token) {
//     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   }
// }

/** Returns the axios instance */
export function getInstance() {
  return instance;
}

export function getUploadHeaders() {
  const defaultHeaders = getInstance().defaults.headers;
  const config = {
    headers: {
      ...defaultHeaders,
      "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
    },
  };
  return config;
}
