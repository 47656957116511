import { getInstance, getUploadHeaders } from "./helpers";

const getFileList = (files, side, type) => {
  return files.filter((file) => {
    return file.docType === type && file.side === side;
  });
};

const groupFiles = (files) => {
  // group files ny type
  const groupedFiles = {};

  files.forEach((file) => {
    const key = `${file.docType}${file.backside ? ":2" : ":1"}${file.required ? ":1" : ":0"}`;

    if (!groupedFiles[key]) {
      groupedFiles[key] = [];
    }
    groupedFiles[key].push(file);
  });

  Object.keys(groupedFiles).forEach((key) => {
    groupedFiles[key] = groupedFiles[key].sort((a, b) => {
      let fa = a.side.toLowerCase(),
        fb = b.side.toLowerCase();

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
  });

  return groupedFiles;
};

const previewFile = async (file) => {
  if (/image/.test(file.type)) {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;

    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  } else if (/pdf/.test(file.type)) {
    const pdfFile = new Blob([file], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(pdfFile);

    window.open(fileURL);
  } else {
    console.error(
      "There was a problem with the file preview!\n Please report this issue to the development team. Thank you =)"
    );
  }
};

async function sendFile(file, docType, parentId) {
  let url = `/api/users/personal-info/upload/${docType}`;

  if (parentId) {
    url = `${url}/parent/${parentId}`;
  }

  const data = new FormData();
  data.append("file", file);

  const uploadHeaders = getUploadHeaders();

  const result = await getInstance().post(url, data, uploadHeaders);

  return result;
}

export { getFileList, groupFiles, previewFile, sendFile };
