/* eslint-disable */
import React, { useEffect, useState } from "react";
import { getPersonalInfo, getUser, fetchDoc, getCompanyInfo } from "../../services/users";
import { useHistory } from "react-router-dom";
import { Alert, Avatar, Col, Divider, Row } from "antd";
import { USER_STATUSES, US_STATES, BALANCE_STATUSES, USER_TYPE } from "eurst-shared/src/enums";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import { getCountries } from "../../services/countries";
import "./index.scss";
import { STATUSES_KYC_COLOR } from "../../constants/constants";
import Spin from "antd/lib/spin";
import { FORMAT_FOR_DATE } from "eurst-admin-part/src/constants/constants";
import Tag from "antd/lib/tag";
import UserStatus from "./user-status";

const addressFormatter = require("@fragaria/address-formatter");

function FullAddress({ address = {}, state }) {
  if (Object.keys(address).length === 0) return <div>N/A</div>;
  return (
    <div>
      {address.address1}
      {address?.address2 && <br />}
      {address.address2}
      <br />
      {address.city} {state} {address.zip}
    </div>
  );
}

function ProfilePage() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [countries, setCountries] = useState([]);
  const [citizenship, setCitizenship] = useState("N/A");
  const [taxState, setTaxState] = useState("N/A");
  const [countryOfResidence, setCountryOfResidence] = useState("N/A");
  const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    const data = await getUser();
    const {
      data: { type, name },
    } = data;
    const personalData = await getPersonalInfo();
    if (type === USER_TYPE.company) {
      const companyData = await getCompanyInfo();

      const {
        data: { companyInfo },
      } = companyData;
      let company = {};
      if (Object.values(companyInfo).length > 0) {
        company = {
          fullName: companyInfo?.included[0]?.attributes?.name,
          name: companyInfo?.data?.attributes?.name,
          number: companyInfo?.data?.attributes?.number,
          organizationLabel: companyInfo?.data?.attributes?.organizationLabel,
          regionOfFormation: companyInfo?.included[0]?.attributes?.regionOfFormation,
          taxIdNumber: companyInfo?.included[0]?.attributes?.taxIdNumber,
          owner: companyInfo?.included[1]?.attributes,
        };
      } else {
        company = {
          fullName: "N/A",
          name: "N/A",
          number: "N/A",
          organizationLabel: "N/A",
          regionOfFormation: "N/A",
          taxIdNumber: "N/A",
          owner: "N/A",
        };
      }
      setCompanyInfo(company);
    }
    const countriesData = await getCountries();
    setUser(data.data);
    setPersonalInfo(personalData.data);
    setCountries(countriesData);
    const taxState =
      personalData.data?.taxState &&
      US_STATES.find((item) => {
        return item.code === personalData.data.taxState;
      });
    setTaxState(taxState ? taxState.name : "N/A");
    let country =
      personalData.data?.citizenship &&
      countriesData.find((item) => {
        return item.code === personalData.data.citizenship;
      });
    if (country) {
      setCitizenship(country && country.name);
    } else {
      setCitizenship("N/A");
    }
    country =
      personalData.data?.countryOfResidence &&
      countriesData.find((item) => {
        return item.code === personalData.data.countryOfResidence;
      });
    setCountryOfResidence(country && country.name);

    setLoading(false);
  };

  useEffect(() => {
    fetchData().then();
  }, []);
  const { status } = user;

  const onClickByDoc = async (item) => {
    setLoading(true);
    const result = await fetchDoc(item.ptDocumentId);
    window.open(result.data.url, "_blank").focus();
    setLoading(false);
  };

  let color = STATUSES_KYC_COLOR[status];

  return (
    <React.Fragment>
      {!loading ? (
        <React.Fragment>
          <Row>
            <Col className="avatar-wrap" span={24}>
              <h2>Account details</h2>
              <Row justify={"center"}>
                <Avatar
                  style={{
                    cursor: "pointer",
                    border: `4px solid ${STATUSES_KYC_COLOR[status]}`,
                  }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  icon={<UserOutlined />}
                />
              </Row>
              <Row justify={"center"}>
                <span>{`${user.userName}`}</span>
              </Row>
            </Col>
          </Row>
          {status === USER_STATUSES.kycRequested.id && (
            <Row className="alert-wrap" justify="center" align="middle">
              <Alert
                message="You have sent the data for verification, wait for the completion of the procedure."
                type="info"
                showIcon
              />
            </Row>
          )}
          {user.disabled && (
            <Row className="alert-wrap" justify="center" align="middle">
              <Alert
                message={
                  <span>
                    Your account has been blocked for some reasons.Please contact out{" "}
                    <a href="mailto:support@eurst.io"> support team </a> for details
                  </span>
                }
                type="warning"
                showIcon
              />
            </Row>
          )}
          <Row className="profile-desk-wrap">
            <Col span={10} offset={2}>
              <Divider orientation="left">Basic Information</Divider>
              <Row>
                {user.type !== USER_TYPE.company ? (
                  <React.Fragment>
                    <span className="profile-label">Full Name:</span>
                    <span className="profile-info">
                      {user?.firstName ? `${user?.firstName} ${user?.lastName}` : "N/A"}
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="profile-label">Full Name Company:</span>
                    <span className="profile-info">
                      {companyInfo?.fullName ? `${companyInfo?.fullName}` : "N/A"}
                    </span>
                  </React.Fragment>
                )}
              </Row>
              {user.type === USER_TYPE.company && (
                <Row>
                  <span className="profile-label">Name Company:</span>{" "}
                  <span className="profile-info">
                    {companyInfo?.name ? companyInfo.name : "N/A"}
                  </span>
                </Row>
              )}
              {user.type === USER_TYPE.company && (
                <Row>
                  <span className="profile-label">Organization Label:</span>{" "}
                  <span className="profile-info">
                    {companyInfo?.organizationLabel ? companyInfo.organizationLabel : "N/A"}
                  </span>
                </Row>
              )}
              <Row>
                <span className="profile-label">Email Address:</span>{" "}
                <span className="profile-info">{user?.email}</span>
              </Row>

              <Row>
                <span className="profile-label">Status:</span>{" "}
                <span className="profile-info">{user?.status ? UserStatus({ user }) : null}</span>
              </Row>
              {user.type === USER_TYPE.naturalPerson && (
                <Row>
                  <span className="profile-label">Date Of Birth:</span>
                  <span className="profile-info">
                    {moment(personalInfo?.dateOfBirth ? personalInfo.dateOfBirth : []).format(
                      FORMAT_FOR_DATE
                    )}
                  </span>
                </Row>
              )}
              {user.type === USER_TYPE.naturalPerson && (
                <Row>
                  <span className="profile-label">Gender:</span>{" "}
                  <span className="profile-info">
                    {personalInfo?.gender ? personalInfo.gender : "N/A"}
                  </span>
                </Row>
              )}
              <Row>
                <span className="profile-label">Phone Number:</span>{" "}
                <span className="profile-info">
                  {personalInfo?.phoneNumber ? personalInfo.phoneNumber : "N/A"}
                </span>
              </Row>
              <Row>
                <span className="profile-label">Full address:</span>{" "}
                <span className="profile-info">
                  <FullAddress
                    address={personalInfo?.address}
                    state={taxState ? taxState : personalInfo?.address?.region}
                  />
                </span>
              </Row>
              <Row>
                <span className="profile-label">Country of residence:</span>{" "}
                <span className="profile-info">{countryOfResidence}</span>
              </Row>
              {user.type === USER_TYPE.naturalPerson && (
                <Row>
                  <span className="profile-label">Citizenship:</span>{" "}
                  <span className="profile-info">{citizenship}</span>
                </Row>
              )}
              <div className="wallet-info-row">
                <Divider orientation="left">Wallet Information</Divider>
                <Row>
                  <span className="profile-label profile-label-wallet">Wallet:</span>{" "}
                  <span className="profile-info profile-info-wallex">
                    {user?.ethAddress ? user.ethAddress : "N/A"}
                  </span>
                </Row>
                <Row>
                  <span className="profile-label profile-label-balance">EURST Balance:</span>{" "}
                  <span className="profile-info profile-info-balance">
                    {user?.balance ? user.balance : "N/A"}
                  </span>
                </Row>
              </div>
              <div className="profile-note-wrap-desctop">
                <Divider orientation="left">Note</Divider>
                <Row>
                  If you wish to edit your details please contact our{" "}
                  <a href="mailto:support@eurst.io"> support team </a>
                </Row>
              </div>
            </Col>
            <Col span={10} offset={2}>
              <Divider orientation="left">
                {user.type === USER_TYPE.naturalPerson ? "KYC Information" : "KYB Information"}
              </Divider>
              <Row>
                <span className="profile-label">PrimeTrust Account ID:</span>{" "}
                <span className="profile-info">{user?.ptAccountId ? user.ptAccountId : "N/A"}</span>
              </Row>
              <Row>
                <span className="profile-label">Tax ID Number:</span>
                <span className="profile-info">
                  {personalInfo?.taxIdNumber ? personalInfo.taxIdNumber : "N/A"}
                </span>
              </Row>
              {/*<Row>Tax State: {US_STATES.find((item) => { item.code === personalInfo.taxState}).name}</Row>*/}
              {/*<Row>Address: {formatted}</Row>*/}

              <Divider orientation="left">Uploaded documents</Divider>
              {personalInfo?.uploadedDocs && personalInfo?.uploadedDocs.length > 0
                ? personalInfo.uploadedDocs.map((item, index) => {
                    return (
                      <div key={index} style={{ textAlign: "left" }}>
                        <a key={index} onClick={() => onClickByDoc(item)}>
                          {item.result.data.attributes["label"]}
                        </a>
                        <br />
                      </div>
                    );
                  })
                : "N/A"}

              <div className="profile-note-wrap-mob">
                <Divider orientation="left">Note</Divider>
                <Row>
                  If you wish to edit your details please contact our{" "}
                  <a href="mailto:support@eurst.io"> support team </a>
                </Row>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <Spin size="large" />
      )}
    </React.Fragment>
  );
}

export default ProfilePage;
