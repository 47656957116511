const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const isArray = function (a) {
  return Array.isArray(a)
}

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i)
    })
  }

  return o
}

function flatten(source, delimiter = '.', filter) {
  const result = {}
  ;(function flat(obj, stack) {
    if (!obj) return
    Object.keys(obj).forEach(function (k) {
      const s = stack.concat([k])
      const v = obj[k]
      if (filter && filter(k, v)) return
      if (typeof v === 'object') flat(v, s)
      else result[s.join(delimiter)] = v
    })
  })(source, [])
  return result
}

function unflattenObject(ob) {
  const result = {}
  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      const keys = i.match(/^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g) // Just a complicated regex to only match a single dot in the middle of the string
      keys.reduce((r, e, j) => {
        return (
          r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? ob[i] : {}) : [])
        )
      }, result)
    }
  }
  return result
}

// omit({a: 1, b: 2, c: 3}, ['c'])  // {a: 1, b: 2}
function omit(obj, omitKeys) {
  return Object.keys(obj).reduce((result, key) => {
    if (!omitKeys.includes(key)) {
      result[key] = obj[key]
    }
    return result
  }, {})
}

module.exports = {
  unflattenObject,
  flatten,
  omit,
  keysToCamel,
}
