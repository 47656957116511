import React, { useMemo } from "react";
import PropTypes from "prop-types";
import List from "antd/lib/list";
import { PT_DOCUMENT_TYPES } from "eurst-shared/src/enums";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import PictureOutlined from "@ant-design/icons/lib/icons/PictureOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import "./index.scss";

const FileIcon = ({ file }) => {
  if (/pdf$/.test(file.type)) {
    return <FileOutlined />;
  }
  return <PictureOutlined />;
};

FileIcon.propTypes = {
  file: PropTypes.object,
};

const DeleteAction = ({ onDelete, file }) => {
  return (
    <div className="ant-upload-list-item-thumbnail" onClick={() => onDelete(file)} title="Delete">
      <DeleteOutlined className="delete" />
    </div>
  );
};

DeleteAction.propTypes = {
  file: PropTypes.object,
  onDelete: PropTypes.func,
};

const FileList = ({ docType, files, onDelete }) => {
  const doc = useMemo(() => PT_DOCUMENT_TYPES.find((row) => row.id === docType), [docType]);

  return (
    <List
      bordered
      dataSource={files}
      header={<h4>{doc.label}</h4>}
      renderItem={(item) => (
        <List.Item
          actions={[
            <span key={`side${item.uid}`}>{item.side}</span>,
            <DeleteAction key={`del${item.uid}`} file={item} onDelete={onDelete} />,
          ]}>
          <List.Item.Meta
            avatar={
              <div className="ant-upload-list-item-thumbnail">
                <FileIcon file={item} />
              </div>
            }
            description={item.name}
          />
        </List.Item>
      )}
    />
  );
};

FileList.propTypes = {
  docType: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
};

const UploadedFilesList = ({ groupedFiles, onDelete }) => {
  return (
    <>
      {Object.keys(groupedFiles).map((key) => {
        const [docType] = key.split(":");
        return (
          <>
            <FileList
              key={docType}
              docType={docType}
              onDelete={onDelete}
              files={groupedFiles[key]}
            />
          </>
        );
      })}
    </>
  );
};

UploadedFilesList.propTypes = {
  groupedFiles: PropTypes.object,
  onDelete: PropTypes.func,
};

export default UploadedFilesList;
