import React from "react";
import Select from "antd/lib/select";

const CustomSelect = (props) => {
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return <Select showSearch filterOption={filterOption} {...props} />;
};

export default CustomSelect;
