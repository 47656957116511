import React from "react";
import { Tag } from "antd";
import { STATUSES_KYC_COLOR } from "eurst-frontend/src/constants/constants";
import { USER_STATUSES, USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function UserStatus({ user }) {
  const color = STATUSES_KYC_COLOR[user.status];

  let label = USER_STATUSES[user.status].label;
  if (user.type === USER_TYPES_AS_OBJECT.company) {
    label = label.replace(/KYC/, "KYB");
  }

  return <Tag color={color}>{label}</Tag>;
}

export default UserStatus;
