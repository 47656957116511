import React from "react";
import { Link } from "react-router-dom";
import { Alert, Divider } from "antd";
import PropTypes from "prop-types";
import {
  KYC_DOCUMENT_CHECK_STATUSES,
  KYC_DOCUMENT_CHECK_STATUSES_AS_OBJECT,
} from "eurst-shared/src/enums";

function AlertDescription({ record }) {
  const status = KYC_DOCUMENT_CHECK_STATUSES.find((r) => r.id === record.status);
  return (
    <div>
      <Divider />
      {status.label}
    </div>
  );
}

function Alerts({ header, data, fullText, link }) {
  const className = fullText ? "" : "truncate";

  return (
    <div>
      <h3>{header}</h3>
      {data.map((row) => {
        if (link && row.status === KYC_DOCUMENT_CHECK_STATUSES_AS_OBJECT.waitingAction) {
          return (
            <Link to={`${link}/${row.id}`}>
              <Alert
                type="warning"
                key={`kyc-required-action${row.id}`}
                description={<AlertDescription record={row} />}
                message={
                  <div title={row.description} className={className}>
                    {row.description}
                  </div>
                }
              />
            </Link>
          );
        }
        return (
          <Alert
            type="warning"
            key={`kyc-required-action${row.id}`}
            description={<AlertDescription record={row} />}
            message={
              <div title={row.description} className={className}>
                {row.description}
              </div>
            }
          />
        );
      })}
      <Divider />
    </div>
  );
}

function UserAlerts({ header, data, fullText = false, link }) {
  return (
    <div>
      {data && data.length > 0 ? (
        <Alerts header={header} data={data} fullText={fullText} link={link} />
      ) : null}
    </div>
  );
}

UserAlerts.defaultProps = {
  fullText: false,
};

UserAlerts.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.array,
  fullText: PropTypes.bool,
  link: PropTypes.string,
};

export default UserAlerts;
