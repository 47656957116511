import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/auth";

function LogoutPage() {
  auth.logout();
  return (
    <>
      <Redirect to="/" />
    </>
  );
}

export default LogoutPage;
