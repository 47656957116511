import React, { useCallback, useContext, useEffect, useMemo } from "react";
import Modal from "antd/lib/modal";
import { EVM_NETWORKS } from "eurst-shared/src/enums";
import detectEthereumProvider from "@metamask/detect-provider";
import { SUPPORTED_NETWORK } from "../constants/constants";
import { useSuperState } from "../hooks";
import { Context } from "../store";

const withMetaMask = (Wrapped) => {
  return function MetaMask(props) {
    const [metaProvider, setMetaProvider] = useSuperState(null, true);
    const [state] = useContext(Context);
    const { ethAddress: userEthAddress } = state.user;
    const METAMASK_MOBILE_APP_URL = useMemo(() => {
      const BASE_URL = "https://metamask.app.link/dapp/";
      const { host, pathname } = window.location;
      return BASE_URL + host + pathname;
    }, []);
    const MESSAGE_FOR_NOT_CONNECTED = useMemo(
      () => (
        <>
          <p>
            Please install{" "}
            <a href={"https://metamask.io/"} rel="noreferrer" target={"_blank"}>
              Metamask{" "}
            </a>
            to your device to start interacting with the app.
          </p>
          <p>
            <i>
              *If you are using mobile or tablet, please proceed in{" "}
              <a href={METAMASK_MOBILE_APP_URL} rel="noreferrer" target={"_blank"}>
                Metamask browser
              </a>
              .
            </i>
          </p>
        </>
      ),
      [METAMASK_MOBILE_APP_URL]
    );

    const handleAccountAddress = useCallback(
      async (accounts = []) =>
        new Promise((resolve, reject) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            reject({ title: "Please connect to MetaMask." });
          } else if (accounts[0].toLowerCase() !== userEthAddress?.toLowerCase()) {
            reject({
              title: "Please switch in MetaMask to the wallet associated with your Wallex account",
            });
          } else {
            return resolve();
          }
        }),
      [userEthAddress]
    );

    useEffect(() => {
      let provider;

      (async () => {
        provider = await detectEthereumProvider({
          mustBeMetaMask: true,
          silent: true,
          timeout: 500,
        });
        setMetaProvider(provider);

        if (provider) {
          // If the provider returned by detectEthereumProvider is not the same as
          // window.ethereum, something is overwriting it, perhaps another wallet.
          if (provider !== window.ethereum) {
            presentWarning("Do you have multiple wallets installed?");
          }

          // Will open the MetaMask UI
          try {
            const accounts = await provider.request({
              method: "eth_requestAccounts",
            });
            await handleAccountAddress(accounts);

            const chainId = await provider.request({ method: "eth_chainId" });
            await handleUnsupportedNetworks(chainId);
          } catch (e) {
            if (e.title) {
              presentWarning(e.title, e?.content);
            } else {
              console.error(e);
            }
          }

          provider.on("accountsChanged", async (accounts) => {
            try {
              await handleAccountAddress(accounts);
            } catch (e) {
              if (e.title) {
                presentWarning(e.title);
              } else {
                console.error(e);
              }
            }
          });

          provider.on("chainChanged", async (chainId) => {
            try {
              await handleUnsupportedNetworks(chainId);
              window.location.reload();
            } catch (e) {
              if (e.title) {
                presentWarning(e.title, e.content);
              }
            }
          });

          provider.on("disconnect", (error) => {
            if (error) {
              presentWarning(error?.message);
            }
          });
        } else {
          presentWarning("Just a one moment!", MESSAGE_FOR_NOT_CONNECTED);
        }
      })();

      return () => provider?.removeAllListeners();
    }, [handleAccountAddress, MESSAGE_FOR_NOT_CONNECTED, setMetaProvider]);

    const presentWarning = (title, content) => {
      Modal.warning({
        title: `${title}`,
        content: content || " ",
      });
    };

    const handleUnsupportedNetworks = (chainId) =>
      new Promise((resolve, reject) => {
        const selectedNetwork = EVM_NETWORKS.find((network) => network.hex === chainId);

        if (SUPPORTED_NETWORK.decimal !== selectedNetwork?.decimal) {
          reject({
            title: `You have changed the Network! We support only ${SUPPORTED_NETWORK.networkName}.`,
            content: ` But your current is: ${selectedNetwork?.networkName}`,
          });
        } else {
          resolve();
        }
      });

    const checkCurrentUser = async () => {
      const accounts = await metaProvider?.request({
        method: "eth_requestAccounts",
      });

      await handleAccountAddress(accounts);
    };

    const checkCurrentNetwork = async () => {
      const chainId = await metaProvider?.request({ method: "eth_chainId" });

      await handleUnsupportedNetworks(chainId);
    };

    const presentWarningByCode = (code, message) => {
      // lists of errors: https://eips.ethereum.org/EIPS/eip-1474#error-codes
      //                  https://eips.ethereum.org/EIPS/eip-1193#provider-errors
      switch (code) {
        case -32002:
          presentWarning(
            "We have already asked you to authorize in MetaMask.",
            "Please, check your browser extension."
          );
          break;
        case 4001:
          presentWarning("Something went wrong...", "You have rejected the transaction!");
          break;
        case -32003:
          presentWarning("Transaction rejected!", "Transaction creation failed");
          break;
        case -32005:
          presentWarning("Limit exceeded!", "Request exceeds defined limit");
          break;
        case -32602:
          presentWarning(
            "Problems with configuration of request: ",
            "The method parameters were invalid."
          );
          break;
        case -32603:
          presentWarning("The configuration of transaction is wrong!", "Internal JSON-RPC error");
          break;
        case "UNPREDICTABLE_GAS_LIMIT":
          presentWarning(
            "Your EURST balance is too low for this transaction!",
            "Try to decrease the amount or get more tokens."
          );
          break;
        default:
          presentWarning("Check your browser!", message);
          break;
      }
    };

    const metaMask = {
      isConnected: () => metaProvider?.isConnected(),
      checkCurrentUser,
      checkCurrentNetwork,
      presentWarning: {
        code: presentWarningByCode,
        notConnected: () => presentWarning("Just a one moment!", MESSAGE_FOR_NOT_CONNECTED),
      },
    };

    return <Wrapped {...props} metaMask={metaMask} />;
  };
};
export default withMetaMask;
