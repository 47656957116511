const { camelCaseFromHypencase } = require('./helpers/string')
const bePackage = require('eurst-backend/package.json')
const whPackage = require('eurst-webhook-v2/package.json')
const rbPackage = require('eurst-redeem-bot/package.json')

const PT_EXCEPTIONS = [
  // 0 -> access_auto_check_failed
  // 2 -> currency_risk_auto_check_failed
  // 8 -> sanctions_screening_auto_check_failed
  {
    type: 'aml-checks',
    name: 'access_auto_check_failed',
    label: '',
    code: 0,
  },
  {
    type: 'aml-checks',
    name: 'currency_risk_auto_check_failed',
    label: '',
    code: 2,
  },
  {
    type: 'aml-checks',
    name: 'sanctions_screening_auto_check_failed',
    code: 8,
  },
  // 0 -> access_auto_check_failed
  // 1 -> address_auto_check_failed
  // 3 -> date_of_birth_auto_check_failed
  // 4 -> deceased_auto_check_failed
  // 6 -> name_and_address_auto_check_failed
  // 7 -> name_auto_check_failed
  // 10 -> tax_id_number_auto_check_failed
  {
    type: 'cip-checks',
    name: 'access_auto_check_failed',
    label: '',
    code: 0,
  },
  {
    type: 'cip-checks',
    name: 'address_auto_check_failed',
    label: '',
    code: 1,
  },
  {
    type: 'cip-checks',
    name: 'date_of_birth_auto_check_failed',
    label: '',
    code: 3,
  },
  {
    type: 'cip-checks',
    name: 'deceased_auto_check_failed',
    label: '',
    code: 4,
  },
  {
    type: 'cip-checks',
    name: 'name_and_address_auto_check_failed',
    label: '',
    code: 6,
  },
  {
    type: 'cip-checks',
    name: 'name_auto_check_failed',
    label: '',
    code: 7,
  },
  {
    type: 'cip-checks',
    name: 'tax_id_number_auto_check_failed',
    label: '',
    code: 10,
  },
  // 0 -> access_auto_check_failed
  // 1 -> address_auto_check_failed
  // 3 -> date_of_birth_auto_check_failed
  // 5 -> document_valid_auto_check_failed
  // 9 -> tax_country_auto_check_failed
  {
    type: 'kyc-document-checks',
    name: 'access_auto_check_failed',
    label: '',
    code: 0,
  },
  {
    type: 'kyc-document-checks',
    name: 'address_auto_check_failed',
    label: '',
    code: 1,
  },
  {
    type: 'kyc-document-checks',
    name: 'date_of_birth_auto_check_failed',
    label: '',
    code: 3,
  },
  {
    type: 'kyc-document-checks',
    name: 'document_valid_auto_check_failed',
    label: '',
    code: 5,
  },
  {
    type: 'kyc-document-checks',
    name: 'tax_country_auto_check_failed',
    label: '',
    code: 9,
  },
]

const PT_EXCEPTIONS_AS_OBJECT = {}
PT_EXCEPTIONS.forEach((row) => {
  let type = camelCaseFromHypencase(row.type)
  if (!PT_EXCEPTIONS_AS_OBJECT[type]) PT_EXCEPTIONS_AS_OBJECT[type] = {}

  PT_EXCEPTIONS_AS_OBJECT[type][row.name] = row
})

const USER_TYPES = [
  {
    id: 'natural_person',
    label: 'Individual person',
  },
  {
    id: 'company',
    label: 'Organization',
  },
]
const USER_TYPES_AS_OBJECT = {}
USER_TYPES.forEach((type) => {
  USER_TYPES_AS_OBJECT[type.id] = type.id
})

const USER_ROLES = {
  user: {
    id: 'user',
    label: 'User',
  },
  manager: {
    id: 'manager',
    label: 'Manager',
  },
  root: {
    id: 'root',
    label: 'Admin',
  },
}

const USER_ROLES_AS_OBJECT = {}
Object.keys(USER_ROLES).forEach((key) => {
  USER_ROLES_AS_OBJECT[USER_ROLES[key].id] = USER_ROLES[key].id
})

const USER_TYPE = {
  naturalPerson: 'natural_person',
  company: 'company',
}

const USER_STATUSES = {
  waiting4confirmation: {
    id: 'waiting4confirmation',
    label: 'Waiting for confirmation',
  },
  kycWaiting: {
    id: 'kycWaiting',
    label: 'Waiting to KYC will be filled',
  },
  kycFilling: {
    id: 'kycFilling',
    label: 'Filling personal info to finish KYC procedure',
  },
  kycRequested: {
    id: 'kycRequested',
    label: 'KYC was requested',
  },
  kycApproved: {
    id: 'kycApproved',
    label: 'Approved',
  },
  kycRejected: {
    id: 'kycRejected',
    label: 'Rejected',
  },
  kycVerification: {
    id: 'kycVerification',
    label: 'Verification',
  },
}

const USER_STATUSES_AS_OBJECT = {}
Object.keys(USER_STATUSES).forEach((key) => {
  USER_STATUSES_AS_OBJECT[USER_STATUSES[key].id] = USER_STATUSES[key].id
})

const BALANCE_STATUSES = {
  success: { id: 'success', label: 'Success' },
  waiting: { id: 'waiting', label: 'Waiting' },
  failed: { id: 'failed', label: 'Failed' },
  reject: { id: 'reject', label: 'Rejected' },
  minting: { id: 'minting', label: 'Minting' },
}

const BALANCE_STATUSES_AS_OBJECT = {}
Object.keys(BALANCE_STATUSES).forEach((key) => {
  BALANCE_STATUSES_AS_OBJECT[BALANCE_STATUSES[key].id] = BALANCE_STATUSES[key].id
})

const BALANCE_TYPES = [
  { id: 'mint', label: 'Mint' },
  { id: 'redeem', label: 'Redeem' },
  { id: 'withdraw', label: 'Withdraw' },
]
const BALANCE_TYPES_AS_OBJECT = {}
BALANCE_TYPES.forEach((type) => {
  BALANCE_TYPES_AS_OBJECT[type.id] = type.id
})

const US_STATES = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  // {
  //   name: 'Hawaii',
  //   code: 'HI',
  // },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  // {
  //   name: 'New York',
  //   code: 'NY',
  // },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
]
const US_STATES_AS_OBJECT = {}
US_STATES.forEach((state) => (US_STATES_AS_OBJECT[state.code] = state.code))

// Enum: "drivers_license" "government_id" "other" "passport" "residence_permit" "utility_bill"
// https://documentation.primetrust.com/#operation/POST__v2_kyc_document_checks
const PT_DOCUMENT_TYPES = [
  {
    label: 'ID Card',
    id: 'government_id',
    identity: true,
    required: true,
  },
  {
    label: 'Passport',
    id: 'passport',
    identity: true,
    proofOfAddress: true,
    required: true,
  },
  {
    label: 'Driver licence',
    id: 'drivers_license',
    backside: true,
    required: true,
  },
  {
    label: 'Residence permit',
    id: 'residence_permit',
    backside: true,
  },
  {
    label: 'Utility bill',
    id: 'utility_bill',
  },
  {
    label: 'Other',
    id: 'other',
  },
]
const PT_DOCUMENT_TYPES_AS_OBJECT = {}
PT_DOCUMENT_TYPES.forEach((row) => {
  PT_DOCUMENT_TYPES_AS_OBJECT[row.id] = row.id
})
// https://documentation.primetrust.com/#tag/Funds-Transfers
// Outgoing funds-transfers are created when a disbursement or refund is created.
//
// Outgoing funds-transfers can only be settled once all of its associated contingent-holds have been cleared.
//
// There are multiple statuses a funds-transfer can take and they are listed below.
//
// pending is the funds-transfer is pending either funds still need to be received or the the outgoing transfer of funds still needs to be processed.
// cancelled is the funds-transfer has been cancelled, only pending funds-transfers can be cancelled.
// settled is the funds-transfer has been settled i.e. funds have been received or funds have left the account at Prime Trust.
// reversed is used if there has been a settlement issue with the funds-transfer, generally during the clearing period for the funds-transfer. An example being an ACH failure or credit card chargeback.
const WITHDRAW_STATUSES = [
  {
    id: 'pending',
    label: 'Waiting',
  },
  {
    id: 'cancelled',
    label: 'Rejected',
  },
  {
    id: 'settled',
    label: 'Success',
  },
  {
    id: 'reversed',
    label: 'Failed',
  },
]
const WITHDRAW_STATUSES_AS_OBJECT = {}
WITHDRAW_STATUSES.forEach((row) => {
  WITHDRAW_STATUSES_AS_OBJECT[row.id] = row.id
})

const EVM_NETWORKS = [
  {
    decimal: 1,
    hex: '0x1',
    networkName: 'Ethereum Main Network (Mainnet)',
  },
  {
    decimal: 3,
    hex: '0x3',
    networkName: 'Ropsten Test Network',
  },
  {
    decimal: 4,
    hex: '0x4',
    networkName: 'Rinkeby Test Network',
  },
  {
    decimal: 5,
    hex: '0x5',
    networkName: 'Goerli Test Network',
  },
  {
    decimal: 42,
    hex: '0x2a',
    networkName: 'Kovan Test Network',
  },
]

const WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY = {}

WITHDRAW_STATUSES.forEach(({ id, label }) => {
  WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY[id] = { id, label }
})

const PURCHASE_SOURCES = [
  {
    id: 'wire',
    label: 'Wire',
  },
  {
    id: 'creditCard',
    label: 'Card',
  },
  {
    id: 'redeem',
    label: 'Redeem',
  },
]

const WITHDRAW_SOURCES = [
  {
    id: 'us',
    label: 'US',
  },
  {
    id: 'international',
    label: 'International',
  },
]

const WITHDRAW_SOURCES_AS_OBJECT = {}
WITHDRAW_SOURCES.forEach(({ id }) => {
  WITHDRAW_SOURCES_AS_OBJECT[id] = id
})

const PURCHASE_SOURCES_AS_OBJECT = {}
PURCHASE_SOURCES.forEach(({ id }) => {
  PURCHASE_SOURCES_AS_OBJECT[id] = id
})

const PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY = {}

PURCHASE_SOURCES.forEach(({ id, label }) => {
  PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY[id] = { id, label }
})

// string (Account Status)
// Enum: "closed" "incomplete" "opened" "pending" "voided" "pending_closure"\

const KYC_REQUIRED_ACTIONS_STATUSES = [
  {
    label: 'Pending',
    id: 'pending',
    color: 'gold',
  },
  {
    label: 'Verified',
    id: 'verified',
    color: 'green',
  },
  {
    label: 'Failed',
    id: 'failed',
    color: 'red',
  },
]

const KYC_REQUIRED_ACTIONS_STATUSES_AS_OBJECT = {}
KYC_REQUIRED_ACTIONS_STATUSES.forEach(({ id }) => {
  KYC_REQUIRED_ACTIONS_STATUSES_AS_OBJECT[id] = id
})

const KYC_DOCUMENT_CHECK_STATUSES = [
  {
    id: 'pending',
    label: 'Pending',
    color: '',
  },
  {
    id: 'waitingAction',
    label: 'Waiting action',
    color: 'lime',
  },
  {
    id: 'verifying',
    label: 'Verifying',
    color: 'gold',
  },
  {
    id: 'failed',
    label: 'Failed',
    color: 'red',
  },
  {
    id: 'verified',
    label: 'Verified',
    color: 'green',
  },
]

const KYC_DOCUMENT_CHECK_STATUSES_AS_OBJECT = {}
KYC_DOCUMENT_CHECK_STATUSES.forEach(({ id }) => {
  KYC_DOCUMENT_CHECK_STATUSES_AS_OBJECT[id] = id
})

const CONTACT_CHECK_FLAGS = [
  {
    id: 'identity-confirmed',
    label: 'Identity confirmed',
  },
  {
    id: 'identity-documents-verified',
    label: 'Identity documents verified',
  },
  {
    id: 'proof-of-address-documents-verified',
    label: 'Proof of address documents verified',
  },
]
const CONTACT_CHECK_FLAGS_AS_OBJECT = {}
CONTACT_CHECK_FLAGS.forEach(({ id }) => {
  CONTACT_CHECK_FLAGS_AS_OBJECT[id.replace(/-/g, '_')] = id
})

const PACKAGE_NAMES = [{ id: bePackage.name }, { id: whPackage.name }, { id: rbPackage.name }]

const PACKAGE_NAMES_AS_OBJECT = {}
PACKAGE_NAMES.forEach(({ id }) => {
  PACKAGE_NAMES_AS_OBJECT[id] = id
})

const LOG_LEVELS = [
  {
    id: 'info',
    label: 'Info',
    color: 'green',
  },
  {
    id: 'warn',
    label: 'Warn',
    color: 'orange',
  },
  {
    id: 'debug',
    label: 'Debug',
    color: 'geekblue',
  },
  {
    id: 'error',
    label: 'Error',
    color: 'red',
  },
]
const LOG_LEVELS_AS_OBJECT = {}
LOG_LEVELS.forEach(({ id }) => {
  LOG_LEVELS_AS_OBJECT[id] = id
})

module.exports = {
  BALANCE_STATUSES,
  BALANCE_STATUSES_AS_OBJECT,
  USER_STATUSES,
  USER_STATUSES_AS_OBJECT,
  USER_TYPE,
  USER_ROLES,
  USER_ROLES_AS_OBJECT,
  BALANCE_TYPES,
  BALANCE_TYPES_AS_OBJECT,
  US_STATES,
  US_STATES_AS_OBJECT,
  PT_DOCUMENT_TYPES,
  PT_DOCUMENT_TYPES_AS_OBJECT,
  WITHDRAW_STATUSES,
  WITHDRAW_STATUSES_AS_OBJECT,
  WITHDRAW_STATUSES_AS_OBJECT_WITH_PROPERTY,
  EVM_NETWORKS,
  PURCHASE_SOURCES,
  PURCHASE_SOURCES_AS_OBJECT,
  PURCHASE_SOURCES_AS_OBJECT_WITH_PROPERTY,
  PT_EXCEPTIONS,
  PT_EXCEPTIONS_AS_OBJECT,
  WITHDRAW_SOURCES,
  WITHDRAW_SOURCES_AS_OBJECT,

  USER_TYPES,
  USER_TYPES_AS_OBJECT,

  KYC_REQUIRED_ACTIONS_STATUSES_AS_OBJECT,
  KYC_REQUIRED_ACTIONS_STATUSES,

  KYC_DOCUMENT_CHECK_STATUSES,
  KYC_DOCUMENT_CHECK_STATUSES_AS_OBJECT,

  CONTACT_CHECK_FLAGS,
  CONTACT_CHECK_FLAGS_AS_OBJECT,

  PACKAGE_NAMES,
  PACKAGE_NAMES_AS_OBJECT,

  LOG_LEVELS,
  LOG_LEVELS_AS_OBJECT,
}
