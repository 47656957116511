/* eslint-disable */
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Row, Space, Typography } from "antd";
import Cookies from "js-cookie";
import "./index.scss";

function StartPage() {
  const history = useHistory();

  const onClickLogin = () => {
    history.push("/login");
  };

  const onClickRegistration = () => {
    history.push("/check-country");
  };

  React.useEffect(() => {
    const token = Cookies.get("token");
    if (!!token) {
      history.push("/main/dashboard");
    }
  }, []);
  return (
    <Row className="start-wrap" justify={"center"}>
      <Typography>
        <Space direction="vertical">
          <Row>
            <Col span={24}>
              <span className="start-page-header">Mint and Redeem EURST</span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span className="start-sub-header">Stable coins backed by real USD deposits</span>
            </Col>
          </Row>
          <div className="start-btn-wrap">
            <Row>
              <Col span={24}>
                <Button className="start-btn" type="primary" onClick={onClickLogin}>
                  Login
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button className="start-btn" type="primary" onClick={onClickRegistration}>
                  Sign Up
                </Button>
              </Col>
            </Row>
          </div>
        </Space>
      </Typography>
    </Row>
  );
}

export default StartPage;
