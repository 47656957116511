/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import auth from "../../services/auth";
import { toast } from "react-toastify";

function VerificationPage() {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  useEffect(async () => {
    try {
      const token = await auth.verification(query.get("token"));
      if (token.status === 200) {
        Cookies.set("token", token.data.token.token);
        toast("Congratulations!\n" + "Your account has been successfully verified!", {
          type: "success",
          position: "bottom-right",
        });
        history.push("/main/dashboard");
      }
    } catch (error) {
      if (error.data.code === "confirm-link-expired") {
        history.push("/resend-reg-code");
        return;
      }

      history.push("/login");
    }
  }, []);

  return <div></div>;
}

export default VerificationPage;
