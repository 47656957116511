/* eslint-disable */
import React, { useContext, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { USER_STATUSES_AS_OBJECT, PT_DOCUMENT_TYPES } from "eurst-shared/src/enums";
import { toast } from "react-toastify";
import Col from "antd/lib/grid/col";
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Upload from "antd/lib/upload";
import CustomDragger from "../../components/CustomDragger";
import UploadedFilesList from "../../components/UploadedFileList";
import FileQualityMessage from "../../components/FileQualityMessage";
import { Context } from "../../store";
import Button from "antd/lib/button";
import { groupFiles, sendFile } from "../../utils/files";
import {
  updateKycDocumentStatus,
  createKycDocumentChecksInPt,
} from "../../services/kyc-document-checks";

function UpdateKYCDocumentPage() {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [state] = useContext(Context);

  const { user } = state;

  const kycDocumentCheck = user.kycDocumentCheckResults.find((r) => {
    return r.id.toString() === id;
  });

  const groupedFiles = useMemo(() => groupFiles(files), [files]);

  if (state.user && ![USER_STATUSES_AS_OBJECT.kycVerification].includes(state.user.status)) {
    return <Redirect to="/main/dashboard" />;
  }
  const docType = PT_DOCUMENT_TYPES.find((type) => type.id === kycDocumentCheck.details.type);
  const maxCount = docType.backside ? 2 : 1;

  const uploadDocuments = async () => {
    setLoading(true);

    try {
      let uploadedFile;

      if (files.length === 1) {
        uploadedFile = await sendFile(files[0], docType.id);
      } else {
        const frontFile = files[0];
        const backFile = files[1];

        uploadedFile = await sendFile(frontFile, docType.id);
        await sendFile(backFile, docType.id, uploadedFile.data.history.ptDocumentId);
      }

      toast(`${docType.label} file uploaded successfully.`, {
        type: "success",
        position: "bottom-right",
      });

      await updateKycDocumentStatus(id);

      await createKycDocumentChecksInPt(id, { docId: uploadedFile.data.history.id });

      history.push("/main/dashboard");
    } catch (error) {
      console.error(error);
      toast(`${error?.data?.message}`, {
        type: "error",
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const removeFile = (file) => {
    setFiles((prev) => [...prev.filter((doc) => doc.uid !== file.uid)]);
  };

  const customUpload = (docType, { file, onSuccess }) => {
    file.id = docType.id;
    setFiles((prev) => [...prev, file]);
    onSuccess();
  };

  const disabledUploadButton = files.length < maxCount;

  return (
    <Row className="upload-kyc-wrap" align={"flex-start"} justify={"center"}>
      <Space direction="vertical">
        <h2>Update your documents</h2>
        <p className="upload-kyc-desc">
          This step is necessary to get access to the full range of EURST operations
        </p>
        <Divider className="upload-devider" orientation="left">
          Document type: {docType.label}
        </Divider>
        <FileQualityMessage />
        <Row align={"middle"} justify={"center"} wrap>
          <Col className="upload-drag-wrap" sm={24} md={18}>
            {docType.backside ? (
              <>
                <CustomDragger
                  docType={docType}
                  onRemove={removeFile}
                  customRequest={({ file, onSuccess }) => {
                    customUpload(docType, { file, onSuccess });
                  }}
                  side="front"
                  files={files}
                />
                <Divider />
                <CustomDragger
                  docType={docType}
                  customRequest={({ file, onSuccess }) => {
                    customUpload(docType, { file, onSuccess });
                  }}
                  onRemove={removeFile}
                  files={files}
                  side="back"
                />
              </>
            ) : (
              <CustomDragger
                docType={docType}
                onRemove={removeFile}
                customRequest={({ file, onSuccess }) => {
                  customUpload(docType, { file, onSuccess });
                }}
                files={files}
                side="single"
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={24} style={{ margin: "2rem 0" }}>
            <h3>Files to upload</h3>
            <UploadedFilesList onDelete={removeFile} groupedFiles={groupedFiles} />
          </Col>
        </Row>
        <Row align={"middle"} justify={"center"} wrap>
          <Col sm={24} style={{ margin: "2rem 0" }}>
            <p className="kyc-finish-note">
              Note:
              <br />
              The KYC procedure check requires 1-5 days.
              <br />
              You will receive an email when it is complete.
              <br />
              After successful completion you will have access to the services.
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Button
            className="primary-button"
            disabled={disabledUploadButton}
            loading={loading}
            onClick={uploadDocuments}
            type="primary">
            Upload documents
          </Button>
        </Row>
      </Space>
    </Row>
  );
}

export default UpdateKYCDocumentPage;
