const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_PERSONAL_INFO":
      return {
        ...state,
        personalInfo: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_OWNER_SETTINGS":
      return {
        ...state,
        ownerSettings: action.payload,
      };
    case "SET_COUNTRY_CODE":
      return {
        ...state,
        countryCode: action.payload,
      };
    case "SET_RESIDENCE_INFO":
      return {
        ...state,
        residenceInfo: action.payload,
      };
    case "SET_AMOUNT_USD":
      return {
        ...state,
        amountUSD: action.payload,
      };
    case "SET_WITHDRAW_AMOUNT":
      return {
        ...state,
        withdrawAmount: action.payload,
      };
    case "SET_AMOUNT_EURST":
      return {
        ...state,
        amountEURST: action.payload,
      };
    case "SET_CURRENT_NAV":
      return {
        ...state,
        currentNav: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
