import React from "react";
import Menu from "antd/lib/menu";
import PropTypes from "prop-types";
import Dropdown from "antd/lib/dropdown";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";

const CustomDropOptions = ({ options, onClick }) => (
  <Menu subMenuCloseDelay triggerSubMenuAction={"click"}>
    {options.map((docType) => (
      <Menu.Item key={docType.id} onClick={() => onClick(docType)}>
        {docType.label}
      </Menu.Item>
    ))}
  </Menu>
);

CustomDropOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

const DocTypeSelector = ({ onClick, docOptions, docType, disabled }) => {
  return (
    <Dropdown
      trigger={["click"]}
      disabled={disabled}
      overlay={<CustomDropOptions options={docOptions} onClick={onClick} />}>
      <a onClick={(e) => e.preventDefault()}>
        {docType.label || "Choose a document type"} <DownOutlined />
      </a>
    </Dropdown>
  );
};

DocTypeSelector.propTypes = {
  docOptions: PropTypes.arrayOf(PropTypes.object),
  docType: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DocTypeSelector;
