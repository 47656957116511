const { utils } = require('ethers')
const BigNumberJs = require('bignumber.js')

const bgnMinus = (one, two) => {
  const max = new BigNumberJs(one)
  const min = new BigNumberJs(two)
  return max.minus(min).toNumber()
}

const bgnPlus = (one, two) => {
  const max = new BigNumberJs(one)
  const min = new BigNumberJs(two)
  return max.plus(min).toNumber()
}

const bgnDiv = (one, two) => {
  const max = new BigNumberJs(one)
  const min = new BigNumberJs(two)
  return max.div(min).toNumber()
}

const fromIntOtEthBN = (amount) => {
  const result = utils.parseUnits(`${amount}`)

  return result
}

const fromEthBNToNormal = (bn) => {
  const result = fromInt256toInt(bn.toString())

  return result
}

const fromInt256toInt = (value) => {
  const result = +value / Math.pow(10, 18)
  return result
}

const fromIntToInt256 = (value) => {
  return +value * Math.pow(10, 18)
}

const roundDown = (num, precision = 2) => {
  precision = Math.pow(10, precision)

  return Math.floor(num * precision) / precision
}

const roundUp = (num, precision = 2) => {
  precision = Math.pow(10, precision)

  return Math.ceil(num * precision) / precision
}

const calculateAmount = (amount, fee /*percents, ex: 2% = 1.02*/) => {
  const amountBN = utils.parseUnits(`${amount}`)

  const feeBN = utils.parseUnits(`${fee}`)

  const bn = amountBN.mul(feeBN)

  const result = fromInt256toInt(bn.toString())

  return result
}

const mul = (val1, val2) => {
  const result = (val1 * 1e22) / Math.floor(val2 * 1e4) / 1e18
  return result
}

const bgnMul = (one, two) => {
  const bnOne = new BigNumberJs(one)
  const result = bnOne.multipliedBy(two)

  return result.toString()
}

const feeAsOne = function (fee) {
  // fee * 0.01 + 1
  const mul = bgnMul(fee, 0.01)
  const result = bgnPlus(mul, 1)
  return result
}

const calculationOfDecimalNumbers = (x) =>
  x.toString().includes('.') ? x.toString().split('.').pop().length : 0

// calculate fee for mint
//  we have:
//    eurAmount
//    amount = rate ^ eurAmount
//    feeAsOne

const round10 = (value, exp = -2) => {
  // Если степень не определена, либо равна нулю...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value)
  }
  value = +value
  exp = +exp
  // Если значение не является числом, либо степень не является целым числом...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Сдвиг разрядов
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
  // Обратный сдвиг
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
}

const calculateMintPaymentData = (eurAmount, rate, feeAsOne) => {
  const clearedAmount = roundDown(eurAmount * rate)
  const totalMiddleAmount = eurAmount * rate * feeAsOne
  const calculatedFee = totalMiddleAmount - clearedAmount
  const fee = roundUp(calculatedFee)
  const totalAmount = fee + clearedAmount
  return { amount: clearedAmount, fee, totalAmount: totalAmount }
}

const calculateRedeemPaymentData = (eurAmount, rate, feeAsOne) => {
  const clearedAmount = roundDown(eurAmount * rate)
  const fee = roundUp(clearedAmount * (feeAsOne - 1))

  const totalAmount = round10(eurAmount * rate * (2 - feeAsOne))

  return { amount: clearedAmount, fee, totalAmount }
}

const revertRedeemPaymentDataToEURST = (usdAmount, rate, feeAsOne) => {
  const clearedAmount = roundUp(usdAmount / rate)
  const fee = roundUp(clearedAmount * (feeAsOne - 1))

  const totalAmount = round10(usdAmount / (rate * (2 - feeAsOne)))

  return { amount: clearedAmount, fee, totalAmount }
}

module.exports = {
  mul,
  bgnMinus,
  bgnPlus,
  roundUp,
  roundDown,
  calculateAmount,
  bgnMul,
  fromIntToInt256,
  fromInt256toInt,
  fromIntOtEthBN,
  bgnDiv,
  fromEthBNToNormal,
  feeAsOne,
  calculationOfDecimalNumbers,
  calculateMintPaymentData,
  calculateRedeemPaymentData,
  revertRedeemPaymentDataToEURST,
}
