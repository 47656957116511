/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getResourceToken, postAccounts } from "../../services/general";
import { toast } from "react-toastify";
import { getPersonalInfo, getUser, patchUserEmail } from "../../services/users";
import { Alert, Row, Typography } from "antd";
import "./style.scss";

const { Paragraph, Text } = Typography;

function KYBPage() {
  const history = useHistory();
  // eslint-disable-next-line
  const [tokenCC, setTokenCC] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {
          attributes: { token },
        } = await getResourceToken();
        const user = await getUser();
        setEmail(user.data.email);
        const element = document.getElementById("main");
        if (element.children.length === 0 && token) {
          window.primeTrustReady = function (pt) {
            pt.launchCreateAccount({
              target: element,
              resourceTokenHash: token,
              events: {
                loaded: function () {
                  console.log("[primetrust] 3. Embedded account creation loaded!");
                },
                resourceCreated: async (accountId) => {
                  console.log("[primetrust] 4. Account created with ID 1: ", accountId);
                  const response = await postAccounts(accountId);
                  if (response?.data?.altEmail !== response?.data?.email) {
                    await patchUserEmail();
                  }
                  toast(`The KYB form has been filled!`, {
                    type: "success",
                    position: "bottom-right",
                  });
                  history.push("/main/dashboard");
                },
              },
            });
          };
        }
      } catch (e) {
        console.error("KYB page", e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const text = (
    <Paragraph>
      EURST recommends using the same e-mail that you provided when Signing in:{" "}
      <Text copyable>{email}</Text> .
    </Paragraph>
  );
  return (
    <React.Fragment>
      <Row className="alert-wrap" justify="center" align="middle">
        {email && <Alert style={{ cursor: "pointer" }} message={text} type="info" showIcon />}
      </Row>
      <div id={"main"} style={{ width: "100%", height: "100%" }}></div>;
    </React.Fragment>
  );
}

export default KYBPage;
