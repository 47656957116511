import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

function postWallet(wallet) {
  const payload = {
    ethAddress: wallet,
  };
  const url = `/api/users/personal-info/eth-address`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().patch(url, payload);
}

function getRate(rate) {
  const url = `/api/currency-rate/one?currency=${rate}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function postPurchases(payload) {
  const url = `/api/purchases`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

function postWithdraw(payload) {
  const url = `/api/withdraw/${payload.type}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

function getEurstInfo() {
  const url = `/api/eurst`;

  return getInstance().get(url);
}

function getFundsTransferMethodsList() {
  const url = `/api/funds-transfer-methods/list?sorder=desc&limit=200`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getWithdrawList() {
  const url = `/api/withdraw/list?sorder=desc&limit=1000`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getPurchasesList(type = "mint") {
  const url = `/api/purchases/${type}/list?sorder=desc&limit=1000`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getCreditCardList() {
  const url = `/api/credit-cards/list?sorder=desc`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getSCProfile() {
  const url = `/api/users/sc-profile`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getCCToken() {
  const url = `/api/purchases/get-cc-token`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

function getCCVerificationCode(resourceId) {
  const payload = {
    resourceId,
  };
  const url = `/api/purchases/get-cc-code`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().post(url, payload);
}

function getDeposit() {
  const url = `/api/purchases/deposit`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return getInstance().get(url);
}

export {
  postWallet,
  getRate,
  postPurchases,
  getPurchasesList,
  getCCToken,
  getSCProfile,
  getDeposit,
  postWithdraw,
  getWithdrawList,
  getCCVerificationCode,
  getCreditCardList,
  getEurstInfo,
  getFundsTransferMethodsList,
};
