import { getInstance } from "../utils/helpers";
import Cookies from "js-cookie";

const getAuthCookies = () => {
  const token = Cookies.get("token");
  const status = Cookies.get("status");

  if (!token) {
    return;
  }
  return {
    token,
    status,
  };
};

const setAuthCookies = (token, status, expiresIn) => {
  const number = Date.now() + parseInt(expiresIn) * 1000;
  const date = new Date(number);

  Cookies.set("token", token, { expires: date });
  Cookies.set("status", status);
};

function registration({ userName, email, password, countryCode, dateOfBirth, type }) {
  const payload = {
    userName,
    email,
    password,
    countryCode,
    dateOfBirth,
    type,
  };
  const url = `/api/auth/register`;
  return getInstance().post(url, payload);
}

function login({ email, password }) {
  const payload = {
    email,
    password,
  };
  const url = `/api/auth`;
  return getInstance()
    .post(url, payload)
    .then((response) => {
      setAuthCookies(
        response.data.token.token,
        response.data.status,
        response.data.token.expiresIn
      );
      return response;
    });
}

function verification(token) {
  const url = `/api/auth/register-confirm`;
  const payload = {
    token,
  };
  return getInstance().put(url, payload);
}

function logout() {
  Cookies.remove("token");
}

function resetPassword(email) {
  const url = `/api/auth/reset-password`;
  const payload = {
    email,
  };
  return getInstance().post(url, payload);
}

function resetPasswordConfirm(token, data) {
  Cookies.set("token", token);

  const url = `/api/auth/reset-password/confirm`;

  return getInstance()
    .put(url, data)
    .finally(() => Cookies.remove("token"));
}

function resendRegLink(data) {
  const url = `/api/auth/resend-confirmation-link`;

  return getInstance().post(url, data);
}

export default {
  login,
  registration,
  verification,
  logout,
  resetPassword,
  resetPasswordConfirm,
  resendRegLink,
  getAuthCookies,
  setAuthCookies,
};
