/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Switch, Layout, Col, Row, Divider, Breadcrumb } from "antd";
import WallexLogo from "../../eurst-symbol.png";
import DollarCircleOutlined from "@ant-design/icons/lib/icons/DollarCircleOutlined";
import { getUser } from "../../services/users";
import { getDeposit, getRate } from "../../services/wallet";
import { Context } from "../../store";
import { Link, useHistory } from "react-router-dom";
import { calculationOfDecimalNumbers, roundDown, roundUp } from "eurst-shared/src/helpers/math";
import Spin from "antd/lib/spin";
import "./index.scss";
import withKYCApproved from "../../hocs/withKYCApproved";

const { Content } = Layout;

function MintProcessPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(true);
  const [state, dispatch] = useContext(Context);
  const [wallet, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [balance, setBalance] = useState(null);
  const [rate, setRate] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [usd, setUsd] = useState("");
  const [errorUsdField, setErrorUsdField] = useState("");
  const [eurst, setEurst] = useState("");
  const [errorEurstField, setErrorEurstField] = useState("");

  const { mintFee, mintFeeAsOne, minMintAmount, maxMintAmount } = state.ownerSettings;

  const onClickButton = () => {
    dispatch({ type: "SET_AMOUNT_USD", payload: usd });
    dispatch({ type: "SET_AMOUNT_EURST", payload: eurst });
    if (paymentMethod) {
      history.push("/main/wire-payment");
    } else {
      history.push("/main/card-payment");
    }
  };

  const fetchUserData = async () => {
    const data = await getUser();
    setBalance(data?.data?.balance ? data.data.balance : null);
    setWallet(data?.data?.ethAddress ? data.data.ethAddress : null);
    setStatus(data?.data?.status ? data.data.status : null);
  };

  const fetchRate = async () => {
    const data = await getRate("USD");
    setRate(data?.data?.USD ? data.data.USD : null);
  };

  const onChangePaymentMethod = (checked) => {
    setPaymentMethod(checked);
  };

  const fetchDeposit = async () => {
    const {
      data: {
        deposit: { disbursable },
      },
    } = await getDeposit();
    setDeposit(disbursable);
  };

  const onChangeUSD = (event) => {
    const normalaizeValue = event.target.value;
    const numbersAfterDot = calculationOfDecimalNumbers(normalaizeValue);
    if (
      (normalaizeValue !== "." &&
        normalaizeValue != 0 &&
        !Number(normalaizeValue) &&
        normalaizeValue.length > 0) ||
      numbersAfterDot > 2
    ) {
      return;
    }
    if (normalaizeValue === ".") {
      setErrorUsdField("Amount is not valid!");
      setUsd(event.target.value);
      return;
    } else {
      setErrorUsdField("");
    }
    const value = calculatedEURST(event.target.value);
    if (Number(value) < Number(minMintAmount)) {
      setErrorEurstField(`Amount should be more than ${minMintAmount}`);
      setUsd(event.target.value);
      setEurst(parseFloat(roundDown(value)));
      return;
    }
    if (Number(value) > Number(maxMintAmount)) {
      setErrorEurstField(`Amount should be less than ${maxMintAmount}`);
      setUsd(event.target.value);
      setEurst(parseFloat(roundDown(value)));
      return;
    }
    setErrorEurstField("");
    setUsd(event.target.value);
    setEurst(parseFloat(roundDown(value)));
  };

  const onChangeEURST = (event) => {
    const normalaizeValue = event.target.value;

    const numbersAfterDot = calculationOfDecimalNumbers(normalaizeValue);
    if (
      (normalaizeValue !== "." &&
        normalaizeValue != 0 &&
        !Number(normalaizeValue) &&
        normalaizeValue.length > 0) ||
      numbersAfterDot > 2
    ) {
      return;
    }
    if (normalaizeValue === ".") {
      setErrorEurstField("Amount is not valid!");
      setEurst(event.target.value);
      return;
    } else {
      setErrorEurstField("");
    }

    if (Number(event.target.value) < Number(minMintAmount)) {
      setErrorEurstField(`Amount should be more than ${minMintAmount}`);
      setEurst(event.target.value);
      return;
    }
    if (Number(event.target.value) > Number(maxMintAmount)) {
      setErrorEurstField(`Amount should be less than ${maxMintAmount}`);
      setEurst(event.target.value);
      return;
    }
    const value = calculatedUSD(event.target.value);
    setEurst(event.target.value);
    setUsd(parseFloat(roundUp(value)));
  };

  const calculatedUSD = (value) => {
    const result = value * rate * parseFloat(mintFeeAsOne);

    return result;
  };

  const calculatedEURST = (value) => value / rate / parseFloat(mintFeeAsOne);

  useEffect(async () => {
    setLoading(true);
    try {
      await fetchUserData();
      await fetchRate();
      await fetchDeposit();
    } catch (e) {
      console.error("mint page", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const validationErrors =
    !!errorUsdField || !!errorEurstField || (Number(usd) === 0 && Number(eurst) === 0);

  const breadcrumbItems = [
    <Breadcrumb.Item key="dashboard">
      <Link to="/main/dashboard">Dashboard</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="mint">
      <Link to="/main/mint-process">Mint</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <React.Fragment>
      <Spin tip="Loading..." spinning={loading}>
        <Layout className="mint-wrap">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          <Content>
            <Row>
              <Col className="mint-col" span={12} offset={2}>
                <Divider orientation="left">Mint EURST</Divider>
                <Form layout="vertical" onValuesChange={() => {}} size={"default"}>
                  <Form.Item label="Destination Address">
                    <Input value={wallet} disabled />
                  </Form.Item>
                  <Form.Item label="Amount">
                    <Input suffix="EURST" value={eurst} placeholder="0" onChange={onChangeEURST} />
                    {errorEurstField && (
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{errorEurstField}</div>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item label="Pay">
                    <Input
                      prefix="$"
                      value={usd}
                      placeholder="0"
                      onChange={onChangeUSD}
                      suffix="USD"
                    />
                    {errorUsdField && (
                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{errorUsdField}</div>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item label="Rate">
                    <span>1 EURST = {rate} USD</span>
                  </Form.Item>
                  <Form.Item>
                    <span>Credit/Debit Card</span>
                    <Switch
                      checked={paymentMethod}
                      onChange={onChangePaymentMethod}
                      style={{ margin: "0 20px" }}
                    />
                    <span>Wire payment</span>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="primary-button"
                      disabled={validationErrors}
                      onClick={onClickButton}
                      type="primary">
                      Proceed to payment
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="mint-col" span={12} offset={2}>
                <Divider orientation="left">EURST balance</Divider>
                <Row>
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <img src={WallexLogo} alt="logo" />
                    </div>
                    <Input disabled value={balance || 0} placeholder="0" />
                  </div>
                </Row>
                <Divider orientation="left">USD deposit at Wallex</Divider>
                <Row className="mint-row-logo">
                  <div className="mint-row-logo">
                    <div className="logo-wrap">
                      <DollarCircleOutlined style={{ fontSize: "60px" }} />
                    </div>
                    <Input disabled value={deposit} placeholder="0" />
                  </div>
                </Row>
                <Row className="note-wrap">
                  <Divider orientation="left"> Note</Divider>
                  <p style={{ textAlign: "left" }}>
                    * The amount due includes {mintFee}% commision fee.
                    <br />
                    ** EURST amount should be between {minMintAmount} and {maxMintAmount}
                    <br />
                    *** Your USD deposit at Wallex will not be deducted from the payment amount due.
                    <br />
                    **** Due to bank wire transfer times, mints may take 1-5 business days to appear
                    after your wire is sent. You will receive an email notification as soon as your
                    wire is received and again when the tokens are minted to your wallet.
                  </p>
                </Row>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </React.Fragment>
  );
}

export default withKYCApproved(MintProcessPage);
