const STATUSES_COLOR = {
  success: "green",
  waiting: "yellow",
  failed: "red",
  reject: "red",
};

const FORMAT_FOR_DATE_WITH_TIME = "DD/MM/YYYY HH:mm:ss";
const FORMAT_FOR_DATE = "DD/MM/YYYY";

// https://logtail.com/team/167932/tail?a=1684400520930.89000329
// https://logtail.com/team/167932/tail?a=1684400571220.76000205
const LOGTAIL_URL = `https://logtail.com/team/${process.env.REACT_APP_LOGTAIL_TEAM}/tail`;

export { STATUSES_COLOR, FORMAT_FOR_DATE, FORMAT_FOR_DATE_WITH_TIME, LOGTAIL_URL };
