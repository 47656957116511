/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Row, Input, Button, Alert, Layout, Space, Form } from "antd";
import { postWallet } from "../../services/wallet";
import { getUser } from "../../services/users";
import { USER_STATUSES } from "../../constants/constants";
import isEthereumAddress from "validator/es/lib/isEthereumAddress";
import { getOwnerSettings } from "../../services/general";
import { Context } from "../../store";
import "./style.scss";
import Spin from "antd/lib/spin";
import { USER_TYPE, USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import UserAlerts from "../../components/Alerts";

const { Content } = Layout;

function DashboardPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [wallet, setWallet] = useState("");
  const [user, setUser] = useState({});
  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(true);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const layout = {
    labelCol: { span: 3 },
  };

  const tailLayout = {
    wrapperCol: { offset: 15 },
  };

  const onFinish = async (values) => {
    const walletResponse = await postWallet(values.wallet);
    if (walletResponse.data.balance) {
      const data = await getUser();
      console.log("data", walletResponse);
      setUser(data.data ? data.data : {});
    }
  };

  const checkEthereumAddress = (rule, value, callback) => {
    const newWallet = form.getFieldValue("wallet");
    if (newWallet && !isEthereumAddress(newWallet)) {
      callback("Eth address is invalid.");
      setDisabled(true);
    } else if (!newWallet) {
      setDisabled(true);
      callback();
    } else {
      setDisabled(false);
      callback();
    }
  };

  const onClickAlert = () => {
    if (!user.ethAddress) {
      form
        .validateFields()
        .then((values) => {})
        .catch((errorInfo) => {
          /*
					errorInfo:
						{
							values: {
								username: 'username',
								password: 'password',
							},
							errorFields: [
								{ name: ['password'], errors: ['Please input your Password!'] },
							],
							outOfDate: false,
						}
					*/
        });
      return;
    }

    if (user.type === USER_TYPE.naturalPerson) {
      history.push("/personal-details");
    } else {
      history.push("/kyb");
    }
  };

  const onClickStart = () => {
    history.push("/main/mint-process");
  };

  const onClickRedeem = () => {
    history.push("/main/redeem-process");
  };

  const onClickWithdraw = () => {
    history.push("/main/withdraw");
  };

  const fetchData = async () => {
    setLoading(true);
    const data = await getUser();
    const response = await getOwnerSettings();
    setUser(data.data ? data.data : {});
    setStatus(data.data.status ? data.data.status : null);
    dispatch({ type: "SET_OWNER_SETTINGS", payload: response.data });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const { balance } = user;
  return (
    <React.Fragment>
      <Content>
        <Spin tip="Loading..." spinning={loading}>
          <div className="dashboard-content-wrap">
            {[
              USER_STATUSES_AS_OBJECT.kycRequested,
              USER_STATUSES_AS_OBJECT.kycApproved,
              USER_STATUSES_AS_OBJECT.kycVerification,
            ].includes(status) ? null : (
              <Row className="alert-wrap" justify="center" align="middle">
                <Alert
                  style={{ cursor: "pointer" }}
                  message="You must confirm your identity before minting or redeeming. Click here to start."
                  type="info"
                  onClick={onClickAlert}
                  showIcon
                />
              </Row>
            )}
            {status === USER_STATUSES.kycRequested && (
              <Row className="alert-wrap" justify="center" align="middle">
                <Alert
                  message="You have sent the data for verification, wait for the completion of the procedure."
                  type="info"
                  showIcon
                />
              </Row>
            )}
            {user.altEmail && user.altEmail !== user.email && (
              <Row className="alert-wrap" justify="center" align="middle">
                <Alert
                  style={{ background: "#db5f8f" }}
                  message="You have provided a different Company's e-mail in this form. Please use the initial e-mail that you provided when Signing in."
                  type="warning"
                  showIcon
                />
              </Row>
            )}
            {user.disabled && (
              <Row className="alert-wrap" justify="center" align="middle">
                <Alert
                  message={
                    <span>
                      Your account has been blocked for some reasons.Please contact out{" "}
                      <a href="mailto:support@eurst.io"> support team </a> for details
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              </Row>
            )}

            <UserAlerts data={user.kycRequiredActions} header={"KYC required actions"} />
            {status === USER_STATUSES_AS_OBJECT.kycVerification && (
              <UserAlerts
                data={user.kycDocumentCheckResults}
                header={"KYC check document failures"}
                link={"/update-kyc-documents"}
              />
            )}
            {status !== USER_STATUSES_AS_OBJECT.kycVerification && (
              <UserAlerts
                data={user.kycDocumentCheckResults}
                header={"KYC check document failures"}
              />
            )}

            <Row justify="center" align="baseline">
              {user.ethAddress ? (
                <div className="dashboard-card-wrap">
                  <Card className="balance-card" title="EURST balance">
                    <Row className="full-width-row">
                      <Input
                        className="balance-input"
                        disabled
                        value={balance || 0}
                        placeholder="0"
                      />
                      <h2>{balance}</h2>
                    </Row>
                    <Row justify="start" style={{ marginTop: 20 }}>
                      <span className="eurst-address">Address: {user.ethAddress}</span>
                    </Row>
                  </Card>
                </div>
              ) : (
                <div className="dashboard-card-wrap">
                  <Card className="add-wallet-card" title="Add your wallet">
                    {/*<Row>*/}

                    {Number.isInteger(balance) ? (
                      <Input disabled value={balance} />
                    ) : (
                      <Form
                        {...layout}
                        form={form}
                        name="nest-messages"
                        onFinish={onFinish}
                        validateMessages={validateMessages}>
                        <Alert
                          message="This ETH address will be tied to your EURST User account. This can't be changed unless you create a different User account."
                          type="warning"
                        />
                        <Form.Item
                          className="wallet-form-row"
                          name={"wallet"}
                          label="Wallet"
                          rules={[{ required: true }, { validator: checkEthereumAddress }]}>
                          <Input
                            onChange={(event) => setWallet(event.target.value)}
                            value={wallet}
                            placeholder="0x..."
                          />
                        </Form.Item>
                        <Form.Item className="add-wallet">
                          <div className="add-wallet-btn-wrap">
                            <Button
                              disabled={disabled}
                              type="primary"
                              className="primary-button"
                              htmlType="submit">
                              Confirm
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    )}
                    {/*</Row>*/}
                  </Card>
                </div>
              )}

              <div className="dashboard-card-wrap">
                <Card className="rendem-card" title="Mint EURST">
                  <p>1. Enter your wallet address</p>
                  <p>2. Choose the payment method: wire or banking card</p>
                  <p>3. Make a payment following the instructions</p>
                  <p>4. Check the EURST sent to your wallet</p>
                  <Button
                    disabled={status !== USER_STATUSES.kycApproved || user.disabled}
                    type="primary"
                    className="primary-button"
                    onClick={onClickStart}>
                    Start
                  </Button>
                </Card>
              </div>
            </Row>
            <Row justify="center" align="baseline">
              <div className="dashboard-card-wrap">
                <Card className="rendem-card" title="Redeem EURST">
                  <p>1. Send the EURST to the redemption address</p>
                  <p>2. Check your USD deposit balance at Wallex</p>
                  <Button
                    disabled={status !== USER_STATUSES.kycApproved || user.disabled}
                    type="primary"
                    className="primary-button"
                    onClick={onClickRedeem}>
                    Start
                  </Button>
                </Card>
              </div>
              <div className="dashboard-card-wrap">
                <Card className="rendem-card" title="Withdraw USD">
                  <p>1. Add your bank account for the wire payment</p>
                  <p>2. Confirm the amount of USD you want to withdraw</p>

                  <Button
                    disabled={status !== USER_STATUSES.kycApproved || user.disabled}
                    type="primary"
                    className="primary-button"
                    onClick={onClickWithdraw}>
                    Start
                  </Button>
                </Card>
              </div>
            </Row>
          </div>
        </Spin>
      </Content>
    </React.Fragment>
  );
}

export default DashboardPage;
