import React from "react";

export const ShieldIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || height}
    viewBox="0 0 24 24"
    width={width || height}
    fill={color}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z" />
  </svg>
);
ShieldIcon.defaultProps = {
  width: "24px",
  height: "24px",
};

export const BusinessIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={width || height}
    viewBox="0 0 24 24"
    width={width || height}
    fill={color}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M20 7h-4V5l-2-2h-4L8 5v2H4c-1.1 0-2 .9-2 2v5c0 .75.4 1.38 1 1.73V19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-3.28c.59-.35 1-.99 1-1.72V9c0-1.1-.9-2-2-2zM10 5h4v2h-4V5zM4 9h16v5h-5v-3H9v3H4V9zm9 6h-2v-2h2v2zm6 4H5v-3h4v1h6v-1h4v3z" />
  </svg>
);
BusinessIcon.defaultProps = {
  width: "24px",
  height: "24px",
};
