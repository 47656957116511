import { EVM_NETWORKS } from "eurst-shared/src/enums";

const USER_STATUSES = {
  waiting4confirmation: "waiting4confirmation",
  kycWaiting: "kycWaiting",
  kycRequested: "kycRequested",
  kycApproved: "kycApproved",
  kycRejected: "kycRejected",
};

const STATUS_MESSAGES = {
  waiting4confirmation: "default",
  kycWaiting: "default",
  kycRequested: "warning",
  kycApproved: "success",
  kycRejected: "error",
};

const STATUSES_KYC_COLOR = {
  waiting4confirmation: "yellow",
  kycWaiting: "yellow",
  kycFilling: "yellow",
  kycRequested: "orange",
  kycApproved: "green",
  kycRejected: "red",
};

const GENDER = {
  male: "male",
  female: "female",
  other: "other",
};

const PT_FILE_ENTITY = {
  idCard: "id-card",
  addressProof: "proof-of-address",
};

const CACHE_TRANSFER_STATUSES = {
  pending: "pending",
  accepted: "accepted",
  rejected: "rejected",
};

const US_STATES = [
  {
    name: "Alabama",
    code: "AL",
  },
  {
    name: "Alaska",
    code: "AK",
  },
  {
    name: "American Samoa",
    code: "AS",
  },
  {
    name: "Arizona",
    code: "AZ",
  },
  {
    name: "Arkansas",
    code: "AR",
  },
  {
    name: "California",
    code: "CA",
  },
  {
    name: "Colorado",
    code: "CO",
  },
  {
    name: "Connecticut",
    code: "CT",
  },
  {
    name: "Delaware",
    code: "DE",
  },
  {
    name: "District Of Columbia",
    code: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    code: "FM",
  },
  {
    name: "Florida",
    code: "FL",
  },
  {
    name: "Georgia",
    code: "GA",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Hawaii",
    code: "HI",
  },
  {
    name: "Idaho",
    code: "ID",
  },
  {
    name: "Illinois",
    code: "IL",
  },
  {
    name: "Indiana",
    code: "IN",
  },
  {
    name: "Iowa",
    code: "IA",
  },
  {
    name: "Kansas",
    code: "KS",
  },
  {
    name: "Kentucky",
    code: "KY",
  },
  {
    name: "Louisiana",
    code: "LA",
  },
  {
    name: "Maine",
    code: "ME",
  },
  {
    name: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Maryland",
    code: "MD",
  },
  {
    name: "Massachusetts",
    code: "MA",
  },
  {
    name: "Michigan",
    code: "MI",
  },
  {
    name: "Minnesota",
    code: "MN",
  },
  {
    name: "Mississippi",
    code: "MS",
  },
  {
    name: "Missouri",
    code: "MO",
  },
  {
    name: "Montana",
    code: "MT",
  },
  {
    name: "Nebraska",
    code: "NE",
  },
  {
    name: "Nevada",
    code: "NV",
  },
  {
    name: "New Hampshire",
    code: "NH",
  },
  {
    name: "New Jersey",
    code: "NJ",
  },
  {
    name: "New Mexico",
    code: "NM",
  },
  {
    name: "New York",
    code: "NY",
  },
  {
    name: "North Carolina",
    code: "NC",
  },
  {
    name: "North Dakota",
    code: "ND",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Ohio",
    code: "OH",
  },
  {
    name: "Oklahoma",
    code: "OK",
  },
  {
    name: "Oregon",
    code: "OR",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Pennsylvania",
    code: "PA",
  },
  {
    name: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Rhode Island",
    code: "RI",
  },
  {
    name: "South Carolina",
    code: "SC",
  },
  {
    name: "South Dakota",
    code: "SD",
  },
  {
    name: "Tennessee",
    code: "TN",
  },
  {
    name: "Texas",
    code: "TX",
  },
  {
    name: "Utah",
    code: "UT",
  },
  {
    name: "Vermont",
    code: "VT",
  },
  {
    name: "Virgin Islands",
    code: "VI",
  },
  {
    name: "Virginia",
    code: "VA",
  },
  {
    name: "Washington",
    code: "WA",
  },
  {
    name: "West Virginia",
    code: "WV",
  },
  {
    name: "Wisconsin",
    code: "WI",
  },
  {
    name: "Wyoming",
    code: "WY",
  },
];

const STATUSES_COLOR = {
  success: "green",
  waiting: "yellow",
  failed: "red",
  reject: "red",
  minting: "blue",
};

const STATUSES_COLOR_FOR_WITHDRAW = {
  settled: "green",
  success: "green",
  waiting: "yellow",
  failed: "red",
  reject: "red",
  pending: "yellow",
  cancelled: "red",
  reversed: "red",
};

const CARD_TYPE = {
  MC: "mastercard",
  VI: "visa",
};

const REQUIRED_PT_DOC_TYPES = ["drivers_license", "government_id", "passport"];

const SUPPORTED_NETWORK = EVM_NETWORKS.find(({ networkName }) => {
  return networkName.toLowerCase().includes(process.env.REACT_APP_NETWORK.toLowerCase());
});

export {
  US_STATES,
  CARD_TYPE,
  CACHE_TRANSFER_STATUSES,
  GENDER,
  PT_FILE_ENTITY,
  STATUS_MESSAGES,
  USER_STATUSES,
  STATUSES_COLOR,
  STATUSES_KYC_COLOR,
  REQUIRED_PT_DOC_TYPES,
  SUPPORTED_NETWORK,
  STATUSES_COLOR_FOR_WITHDRAW,
};
