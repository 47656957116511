import React from "react";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/row";

export default function FileQualityMessage() {
  return (
    <Row align={"middle"} justify={"center"} wrap>
      <Col className="upload-kyc-settings" sm={24} md={12} style={{ margin: "0" }}>
        The image must be high quality, unobstructed and uncropped.
        <br />
        The image must show a full document page (all 4 corners must be visible)
      </Col>
    </Row>
  );
}
