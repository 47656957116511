import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSuperState } from "./";

/**
 * This hook provides an ability to load initial state from url's hash/fragment/anchor and refresh it after state is changed
 * @param {*} initState
 * @returns {[*, function(*): void]}
 */
export default function useFragmentState(initState) {
  const history = useHistory();
  const { location } = history;
  const stateFromHash = location.hash?.substr(1);
  const [state, _setState] = useSuperState(stateFromHash || initState, true);

  useEffect(() => {
    if (!stateFromHash) {
      history.push({ hash: initState });
    }
  }, [history, initState, stateFromHash]);

  const setState = useCallback(
    (newState) => {
      history.push({ hash: newState });
      _setState(newState);
    },
    [_setState, history]
  );

  return [state, setState];
}
