import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Cookies from "js-cookie";
import auth from "../../services/auth";

import "./index.scss";

const validateMessages = {
  required: "${label} is required",
  types: {
    email: "Please enter a valid email address!",
  },
};

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      history.push("/main/dashboard");
    }
  }, [history]);

  const onClickCancel = () => {
    history.goBack();
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const result = await auth.resetPassword(values.email);

      toast(result.data.message, {
        type: "success",
      });
      history.push("/");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-wrap">
      <h1 className="registration-header">Reset your password</h1>
      <Card className="login-card" type={"inner"}>
        <Form
          name="basic"
          initialValues={{ email: "" }}
          onFinish={onFinish}
          onFieldsChange={([email]) => setIsEmailValid(!email.errors.length)}
          validateMessages={validateMessages}>
          <Form.Item
            className="reset-row-wrap"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
            extra={<div>Confirmation link will be sent to email</div>}>
            <Input placeholder="Your account email" />
          </Form.Item>

          <Form.Item>
            <Row>
              <Button className="secondary-button" onClick={onClickCancel}>
                Cancel
              </Button>
              <Button
                disabled={!isEmailValid}
                className="primary-button"
                type="primary"
                htmlType="submit"
                loading={loading}>
                Submit
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPasswordPage;
